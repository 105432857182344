/* global google */
import tileListener from "@/components/map/print/tileListener"
// import store from "@/store"

export const TERRAIN_LAYER_MAX_ZOOM = 19

// TS 2023-10-02: Is this class still in use? It is not used in the MapTerrainLayer component.

class TerrainLayer {
  constructor (map) {
    this.visible = false
    this.createTangerFreeMapType()
    /*
    // Below is the old code for getting terrain map from MapProxy at mp2.wehuntapp.com
    const credentials = store.getters["auth/getCredentials"]
    const basicAuth = btoa(`${credentials.email}:${credentials.password}`)
    const headers = new Headers({
      Authorization: `Basic ${basicAuth}`
    })
    fetch("https://mp2.wehuntapp.com/auth.php", {
      headers: headers,
      credentials: "include"
    })
      .then((res) => res.json())
      .then((json) => {
        this.map = map
        this.mapType = this.createMapType(json.token)
        this.updateLayerVisibility()

        tileListener.addTileLoadListener("terrainLayer", this.mapType, 20000)
      })
    */
  }

  // Free base map from Tanger (not licensed maps, since Tanger does not have a license for web use)
  createTangerFreeMapType () {
    fetch("https://tanger.tracker.fi/api/v3/maps/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        application: "I7f6rYyWz3VSCV97tyrFrzbMb3Rmigni",
        language: "se"
      })
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.base_maps) {
          const map = json.base_maps.find(
            (map) => map.id === "tanger-world/fancy-d1DeZA"
          )
          if (map != null) {
            const url = map.url
            // "https://tanger.tracker.fi/tiles/world/fancy-d1DeZA/{G}/{W}/{Z}/{Y}/{X}?token=..."
            this.mapType = new google.maps.ImageMapType({
              name: "terrainLayer",
              tileSize: new google.maps.Size(256, 256),
              maxZoom: 16,
              getTileUrl (tile, zoom) {
                const { x, y } = tile
                const ix = zoom + "-" + x + "-" + y

                tileListener.addPendingUrl("terrainLayer", ix)

                return url
                  .replace("{G}", map.generation)
                  .replace("{W}", 768)
                  .replace("{Z}", zoom)
                  .replace("{Y}", y)
                  .replace("{X}", x)
              }
            })

            this.updateVisibility()
          }
        }
      })
  }

  createMapType (token) {
    return new google.maps.ImageMapType({
      name: "terrainLayer",
      tileSize: new google.maps.Size(256, 256),
      maxZoom: TERRAIN_LAYER_MAX_ZOOM,
      getTileUrl (tile, zoom) {
        if (zoom > TERRAIN_LAYER_MAX_ZOOM) {
          return null
        }

        const { x, y } = tile
        const ix = zoom + "-" + x + "-" + y

        tileListener.addPendingUrl("terrainLayer", ix)
        return `https://mp2.wehuntapp.com/webmercator512/${zoom}/${x}/${y}.png?token=${token}`
      }
    })
  }

  updateLayerVisibility () {
    if (!this.mapType || !this.map) return

    if (this.visible) {
      this.map.overlayMapTypes.insertAt(0, this.mapType)
    } else {
      const i = this.map.overlayMapTypes.indexOf(this.mapType)
      if (i !== -1) {
        this.map.overlayMapTypes.removeAt(i)
      }
    }
  }

  setVisible (visible) {
    this.visible = visible
    this.updateLayerVisibility()
  }
}

export default TerrainLayer
