<template>
  <div class="panels">
    <RouterView />

    <FilterPanel v-show="isFilterActive" />
    <PrintPanel v-show="isPrintActive" :map="map" />
    <ImportPanel v-show="isImportActive" />
    <ExportPanel v-show="isExportActive" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterPanel from './filterPanel/FilterPanel.vue'
import ImportPanel from '../../import/ImportPanel.vue'
import PrintPanel from './PrintPanel.vue'
import ExportPanel from './ExportPanel.vue'

export default {
  components: {
    FilterPanel,
    PrintPanel,
    ImportPanel,
    ExportPanel
  },

  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },

    map: {
      type: Object,
      default: () => null
    }
  },

  computed: {
    ...mapGetters({
      isFilterActive: 'map/isFilterActive',
      isPrintActive: 'map/isPrintActive',
      isImportActive: 'map/isImportActive',
      isExportActive: 'map/isExportActive'
    })
  }
}
</script>
