<template>
  <Dialog
    :title="title"
    :closable="true"
    :buttons="buttons"
    @close="close">
    <Alert v-if="objectsWithEmptyNames.length > 0" type="danger">
      <span v-html="$tc('map.import.objectsWithEmptyNamesWarningMessage1', objectsWithEmptyNames.length, { count: objectsWithEmptyNames.length })"></span>
      {{ $tc('map.import.objectsWithEmptyNamesWarningMessage2', objectsWithEmptyNames.length) }}
    </Alert>

    <p class="message-title">{{ $t('map.import.confirmMessage') }}</p>

    <ul>
      <li v-if="selectedBoundaries.length > 0">
        <strong>{{ selectedBoundaries.length }}</strong> {{ $tc('map.import.boundaryPlural', selectedBoundaries.length, { count: selectedBoundaries.length }) }}
      </li>

      <li v-if="selectedSubAreas.length > 0">
        <strong>{{ selectedSubAreas.length }}</strong> {{ $tc('map.import.subAreaPlural', selectedSubAreas.length, { count: selectedSubAreas.length }) }}
        <Icon v-if="subAreasWithEmptyNames.length > 0" name="icon-alert-triangle" class="has-text-danger" />
      </li>

      <li v-if="selectedForbiddenAreas.length > 0">
        <strong>{{ selectedForbiddenAreas.length }}</strong> {{ $tc('map.import.forbiddenAreaPlural', selectedForbiddenAreas.length, { count: selectedForbiddenAreas.length }) }}
        <Icon v-if="forbiddenAreasWithEmptyNames.length > 0" name="icon-alert-triangle" class="has-text-danger" />
      </li>

      <li v-if="selectedMarkers.length > 0">
        <strong>{{ selectedMarkers.length }}</strong> {{ $tc('map.import.markerPlural', selectedMarkers.length, { count: selectedMarkers }) }}
        <Icon v-if="markersWithEmptyNames.length > 0" name="icon-alert-triangle" class="has-text-danger" />
      </li>
    </ul>
  </Dialog>
</template>

<script>
import Dialog from '@/components/ui/dialog2/Dialog2.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Dialog
  },

  computed: {
    ...mapGetters({
      selectedBoundaries: 'map/import/getSelectedBoundaries',
      selectedSubAreas: 'map/import/getSelectedSubAreas',
      selectedForbiddenAreas: 'map/import/getSelectedForbiddenAreas',
      selectedMarkers: 'map/import/getSelectedMarkers'
    }),

    title () {
      return this.$i18n.t('map.import.confirmTitle')
    },

    buttons () {
      return [
        {
          title: this.$t('general.cancel'),
          click: this.close
        },
        {
          title: this.submitButtonTitle(),
          type: 'primary',
          click: this.confirm,
          disabled: this.objectsWithEmptyNames.length > 0
        }
      ]
    },

    objectsWithEmptyNames () {
      return [...this.subAreasWithEmptyNames, ...this.forbiddenAreasWithEmptyNames, ...this.markersWithEmptyNames]
    },

    subAreasWithEmptyNames () {
      return this.selectedSubAreas.filter(this.filterEmptyName)
    },

    forbiddenAreasWithEmptyNames () {
      return this.selectedForbiddenAreas.filter(this.filterEmptyName)
    },

    markersWithEmptyNames () {
      return this.selectedMarkers.filter(this.filterEmptyName)
    }
  },

  methods: {
    submitButtonTitle () {
      return this.$i18n.t('map.import.doImport')
    },

    close () {
      this.$emit('close')
    },

    confirm () {
      this.$emit('confirm')
    },

    filterEmptyName (object) {
      return object.name.trim().length === 0
    }
  }
}
</script>

<style scoped>
.message-title {
  font-weight: 600;
}

ul li {
  padding-bottom: 10px;
}
</style>
