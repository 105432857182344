<template>
  <Portal to="modal">
    <div class="progress-modal is-active">
      <h1 class="title has-text-white">{{ title }}</h1>
      <h2 class="title has-text-white">{{ total }}%</h2>

      <progress
        :value="total"
        class="progress is-primary"
        max="100">
      </progress>

      <slot />
    </div>
  </Portal>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: ''
    },

    progress: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      total: 0
    }
  },

  watch: {
    progress (progress) {
      this.total = progress
    }
  }
}
</script>

<style lang="scss">
.progress-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.85);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20vw;
}
</style>
