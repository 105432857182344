<template>
  <a
    :class="{ 'is-primary': isPrintActive }"
    :title="$t('map.tools.print')"
    class="button is-white"
    @click="togglePrint">
    <Icon name="icon-printer" />
  </a>
</template>

<script>
export default {
  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId
    }
  },

  computed: {
    hasPro () {
      return this.$store.getters['subscription/hasPro']
    },

    isPrintActive: {
      get () { return this.$store.getters['map/isPrintActive'] },
      set (state) { return this.$store.dispatch('map/isPrintActive', state) }
    }
  },

  methods: {
    togglePrint () {
      this.isPrintActive = !this.isPrintActive
    }
  }
}
</script>
