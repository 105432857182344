<template>
  <div v-if="!isAdjusting" class="toolbox">
    <div :class="[activeClass]" class="dropdown is-up">
      <div class="dropdown-trigger">
        <a class="button is-medium is-info toolbox-button" @click="toggleDropDown">
          <Icon name="icon-plus" />
        </a>
      </div>

      <div class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a
            v-for="(tool, i) in tools"
            :key="i+10"
            :title="tooltip(name(tool), isDisabled(tool))"
            :class="{ 'is-tool-disabled': isDisabled(tool) }"
            class="dropdown-item"
            @click="goTo(tool.path, isDisabled(tool))">
            <Icon :name="tool.icon" class="push-right-xxs" />
            <span>{{ name(tool) }}</span>
          </a>

          <a class="dropdown-item" @click="drawLine">
            <Icon name="icon-bar-chart-2" class="push-right-xxs" />
            <span>{{ $t('map.general.tools.drawLine') }}</span>
          </a>

          <a class="dropdown-item" @click="drawArrow">
            <Icon name="icon-arrow-right" class="push-right-xxs" />
            <span>{{ $t('map.general.tools.drawArrow') }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventManager } from '@/main'
import { tools, links } from '@/components/map/utils/tools'

export default {
  data () {
    return {
      tools: tools,
      links: links,
      isActive: false,
      isDrawActive: false,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters({
      hasPro: 'subscription/hasPro',
      boundary: 'map/boundary/boundary'
    }),

    selected: {
      get () { return this.$store.getters['map/boundary/selected'] },
      set (state) { this.$store.dispatch('map/boundary/select', state) }
    },

    isPrinting: {
      get () { return this.$store.getters['map/print/isPrinting'] },
      set (bool) { return this.$store.commit('map/print/isPrinting', bool) }
    },

    isAdjusting: {
      get () { return this.$store.getters['map/print/isAdjusting'] },
      set (bool) { return this.$store.commit('map/print/isAdjusting', bool) }
    },

    activeClass () {
      return this.isActive ? 'is-active' : ''
    }
  },

  created () {
    eventManager.$on('map:printEnd', () => {
      // this.isAdjusting = false
      this.isLoading = false
    })
  },

  methods: {
    toggleDropDown () {
      if (!this.isActive) {
        this.showDropDown()
      } else {
        this.closeDropdown()
      }
    },

    showDropDown () {
      this.isActive = true

      setTimeout(() => {
        document.addEventListener('click', this.closeDropdown)
      }, 0)
    },

    closeDropdown () {
      this.isActive = false
      document.removeEventListener('click', this.closeDropdown)
    },

    goTo (path, state) {
      if (state) return

      this.isActive = false

      const methods = ['addMarker', 'addSubArea', 'toggleEditBoundary', 'addForbiddenArea', 'filter', 'print']
      const routes = ['settings', 'import', 'export']

      if (methods.some(fn => path.indexOf(fn) >= 0)) {
        this[path]()
      } else if (routes.some(fn => path.indexOf(fn) >= 0)) {
        this.$router.push({ name: path })
      } else {
        this.$router.push({ name: 'map' })
      }
    },

    addMarker () {
      const drawingMode = this.$store.getters['map/getDrawingMode']
      this.$store.dispatch('map/setDrawingMode', drawingMode == null ? 'marker' : null)
    },

    addSubArea () {
      const drawingMode = this.$store.getters['map/getDrawingMode']
      this.$store.dispatch('map/setDrawingMode', drawingMode == null ? 'subArea' : null)
    },

    addForbiddenArea () {
      const drawingMode = this.$store.getters['map/getDrawingMode']
      this.$store.dispatch('map/setDrawingMode', drawingMode == null ? 'forbiddenArea' : null)
    },

    toggleEditBoundary () {
      this.selected = !this.selected

      if (this.selected) {
        this.$router.push({ name: 'boundary' })
      }
    },

    print () {
      this.isAdjusting = true
    },

    isDisabled (item) {
      const disabled = ['import', 'export', 'print']
      return !this.hasPro && disabled.includes(item.path)
    },

    toggleDraw () {
      this.isDrawActive = !this.isDrawActive
    },

    drawLine () {
      this.toggleDraw()
      this.$store.dispatch('map/setDrawingMode', 'line')
      this.isActive = false
    },

    drawArrow () {
      this.toggleDraw()
      this.$store.dispatch('map/setDrawingMode', 'arrow')
      this.isActive = false
    },

    tooltip (name, isDisabled) {
      if (isDisabled) {
        return this.$t('general.needsPro')
      } else {
        return name
      }
    },

    name (tool) {
      if (tool.path === 'toggleEditBoundary') {
        return this.boundary && this.boundary.coordinates.length > 0
          ? `${this.$t('general.edit')} ${this.$t('map.general.boundary')}`
          : `${this.$t('general.draw')} ${this.$t('map.general.boundary')}`
      }

      return this.$t(tool.name)
    }
  }
}
</script>

<style lang="scss">
.toolbox {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  z-index: 10;

  .dropdown {
    .dropdown-content {
      max-height: 20rem;
      overflow: auto;
      touch-action: none; // disable pinch-to-zoom iOS
    }

    &:focus {
      outline: 0;
    }
  }

  .toolbox-button {
    border-radius: 921024px;
  }
}
</style>
