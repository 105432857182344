<template>
  <div class="layer-marker-group">
    <div class="header" @click="expanded = !expanded">
      <div class="flex align-center">
        <i
          :class="[iconClass]"
          class="icon is-pulled-left eye"
          @click.stop.prevent="toggleVisibility">
        </i>

        <img
          :class="[dimmedClass]"
          :src="icon"
          class="marker-icon is-pulled-left" />

        <span :class="[dimmedClass]" class="title">
          <span>{{ title }}</span>
          <span class="count">({{ itemCount }})</span>
        </span>
      </div>

      <i class="icon is-pulled-right is-clickable">
        <span :class="chevron" />
      </i>
    </div>

    <div v-show="expanded">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    icon: {
      type: String,
      default: ''
    },

    itemCount: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      visible: true,
      expanded: false
    }
  },

  computed: {
    isVisible () {
      return this.visible
    },

    iconClass () {
      return {
        'icon-eye': this.isVisible,
        'icon-eye-off': !this.isVisible,
        'is-dimmed': !this.isVisible
      }
    },

    dimmedClass () {
      return {
        'is-dimmed': !this.isVisible
      }
    },

    chevron () {
      return this.expanded ? 'icon-chevron-up' : 'icon-chevron-down'
    }
  },

  methods: {
    toggleVisibility () {
      this.setVisible(!this.visible)

      if (this.$parent.updateVisibility !== undefined) {
        this.$parent.updateVisibility()
      }
    },

    setVisible (visibility) {
      this.visible = visibility

      this.$children.forEach(marker => {
        if (marker.setVisible !== undefined) {
          marker.setVisible(visibility)
        }
      })
    },

    hasVisibleChildren () {
      for (let i = 0; i < this.$children.length; i++) {
        const child = this.$children[i]

        if (child.isVisible !== undefined) {
          if (child.isVisible) {
            return true
          }
        }
      }

      return false
    },

    updateVisibility () {
      this.visible = this.hasVisibleChildren()

      if (this.$parent.updateVisibility !== undefined) {
        this.$parent.updateVisibility()
      }
    }
  }
}
</script>

<style lang="scss">
.layer-marker-group {
  border-bottom: 0.0625rem solid rgb(240, 240, 240);
  color: black;

  &:last-child {
    border-bottom: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0.25rem;

    .eye {
      margin-right: 0.25rem;
    }

    .marker-icon {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.25rem;
    }

    .title {
      color: black;
      font-weight: 500;
      font-size: 0.85rem;
      user-select: none;
      margin-bottom: 0;

      .count {
        color: grey;
        font-size: 0.8125rem;
        font-weight: normal;
        margin-left: 0.25rem;
      }
    }
  }
}
</style>
