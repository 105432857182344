<script>
/* global google */
import LabelOverlay from '@/components/map/overlays/labelOverlay'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },

    location: {
      type: Object,
      required: true
    },

    visible: {
      type: Boolean,
      default: true
    },

    size: {
      type: Number,
      default: 1
    },

    offset: {
      type: Number,
      default: 0
    },

    labelClass: {
      type: String,
      default: 'marker-label-overlay'
    }
  },

  inject: [
    'getMap'
  ],

  data () {
    return {
      $map: null,
      $label: null
    }
  },

  watch: {
    label () {
      if (this.$label) {
        this.$label.label = this.label
        this.updateVisibility()
      }
    },

    location () {
      if (this.$label) {
        this.$label.setPosition(this.location)
      }
    },

    visible () {
      if (this.$label) {
        this.updateVisibility()
      }
    },

    size (size) {
      if (this.$label) {
        this.$label.size = size
      }
    },

    offset () {
      if (this.$label) {
        this.$label.offset = this.offset
      }
    }
  },

  async mounted () {
    this.$map = await this.getMap()

    const position = new google.maps.LatLng(this.location.lat, this.location.lng)

    this.$label = new LabelOverlay({
      position,
      text: this.label,
      type: 'marker',
      size: this.size,
      offset: this.offset,
      class: [this.labelClass]
    })

    this.updateVisibility()
  },

  destroyed () {
    if (this.$label) {
      this.$label.setMap(null)
    }
  },

  render () {
    return ''
  },

  methods: {
    updateVisibility () {
      const visible = this.visible && this.label.length > 0
      this.$label.setMap(visible ? this.$map : null)
    }
  }
}
</script>
