<template>
  <div>
    <div class="upload-icon">
      <Icon name="icon-upload" class="is-x-large" />
    </div>

    <Alert v-if="hasError" type="danger">
      {{ $t('map.import.readFailedErrorMesssage') }}
    </Alert>

    <p class="has-text-centered" v-html="$t('map.import.content')"></p>

    <div class="field file-field">
      <div v-if="!isLoading" class="file">
        <label class="file-label">
          <input
            ref="gpx"
            class="file-input"
            type="file"
            name="resume"
            accept=".gpx"
            @change="readFile($event)" />

          <span class="file-cta">
            <span class="file-label">{{ $t('map.import.selectFile') }}</span>
          </span>
        </label>
      </div>

      <InlineLoader v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { IMPORT_STATUS_READING, IMPORT_STATUS_READ_FAILED } from '@/store/map/import/importStore.js'

export default {
  computed: {
    ...mapGetters({
      importStatus: 'map/import/getStatus'
    }),

    isLoading () {
      return this.importStatus === IMPORT_STATUS_READING
    },

    hasError () {
      return this.importStatus === IMPORT_STATUS_READ_FAILED
    },

    isIE () {
      return this.$utils.isInternetExplorer()
    }
  },

  methods: {
    readFile (event) {
      this.$store.dispatch('map/import/readFile', event.target.files[0])
    }
  }
}
</script>

<style scoped>
.upload-icon {
  text-align: center;
  margin-bottom: 1.5rem;
}

.file-field {
  display: flex;
  justify-content: center;
}
</style>
