<template>
  <Panel :isActive="isActive" class="filter-panel">
    <PanelTitle :back="false" @close="close">
      <span slot="title">{{ $t('map.filter.title') }}</span>

      <div
        slot="controllers"
        :class="{ 'has-addons': searchText.length > 0 }"
        class="field has-addons push-bottom-xxs">
        <div class="control is-expanded">
          <TextInputField v-model="searchText" :placeholder="$t('map.filter.searchPlaceholder')" />
        </div>

        <div v-show="searchText.length > 0" class="control">
          <Button icon="icon-x" @click="resetFilter" />
        </div>

        <div class="control">
          <Button icon="icon-home" @click="resetVisibility" />
        </div>
      </div>
    </PanelTitle>

    <PanelContent scrollable>
      <div v-if="isLoading">
        {{ $t('general.loadingContent') }}
      </div>

      <Fragment v-else>
        <FilterGroup
          v-if="boundary !== null"
          :background-color="color('boundary')"
          :title="$t('map.general.boundaries')"
          text-color="white"
          icon="icon-map">
          <FilterBoundary v-if="boundary" />
        </FilterGroup>

        <FilterGroup
          v-if="subAreas.length > 0"
          :item-count="subAreas.length"
          :background-color="color('subArea')"
          :title="$t('map.general.subAreas')"
          text-color="white"
          icon="icon-border">
          <FilterSubArea v-for="subArea in subAreas" :key="subArea.id" :sub-area="subArea" />
        </FilterGroup>

        <FilterGroup
          v-if="forbiddenAreas.length > 0"
          :item-count="forbiddenAreas.length"
          :background-color="color('forbiddenArea')"
          :title="$t('map.general.forbiddenAreas')"
          text-color="white"
          icon="icon-warning">
          <FilterSubArea v-for="forbiddenArea in forbiddenAreas" :key="forbiddenArea.id" :sub-area="forbiddenArea" />
        </FilterGroup>

        <FilterGroup
          v-if="markers.length > 0"
          :item-count="markers.length"
          :background-color="color()"
          :title="$t('map.general.markers')"
          text-color="black"
          icon="icon-location">
          <FilterMarkerGroup
            v-if="passMarkers.length > 0"
            :item-count="passMarkers.length"
            :icon="getMarkerTypeIcon('pass')"
            :title="$t('map.markers.pass')">
            <FilterMarker v-for="marker in passMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="towerMarkers.length > 0"
            :item-count="towerMarkers.length"
            :icon="getMarkerTypeIcon('tower')"
            :title="$t('map.markers.tower')">
            <FilterMarker v-for="marker in towerMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="foodPlaceMarkers.length > 0"
            :item-count="foodPlaceMarkers.length"
            :icon="getMarkerTypeIcon('food_place')"
            :title="$t('map.markers.food_place')">
            <FilterMarker v-for="marker in foodPlaceMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="baitMarkers.length > 0"
            :item-count="baitMarkers.length"
            :icon="getMarkerTypeIcon('bait')"
            :title="$t('map.markers.bait')">
            <FilterMarker v-for="marker in baitMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="gatherPlaceMarkers.length > 0"
            :item-count="gatherPlaceMarkers.length"
            :icon="getMarkerTypeIcon('gathering_place')"
            :title="$t('map.markers.gathering_place')">
            <FilterMarker v-for="marker in gatherPlaceMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="bridgeMarkers.length > 0"
            :item-count="bridgeMarkers.length"
            :icon="getMarkerTypeIcon('bridge')"
            :title="$t('map.markers.bridge')">
            <FilterMarker v-for="marker in bridgeMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="fordMarkers.length > 0"
            :item-count="fordMarkers.length"
            :icon="getMarkerTypeIcon('ford')"
            :title="$t('map.markers.ford')">
            <FilterMarker v-for="marker in fordMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="saltLickMarkers.length > 0"
            :item-count="saltLickMarkers.length"
            :icon="getMarkerTypeIcon('salt_lick')"
            :title="$t('map.markers.salt_lick')">
            <FilterMarker v-for="marker in saltLickMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="barMarkers.length > 0"
            :item-count="barMarkers.length"
            :icon="getMarkerTypeIcon('bar')"
            :title="$t('map.markers.bar')">
            <FilterMarker v-for="marker in barMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="camMarkers.length > 0"
            :item-count="camMarkers.length"
            :icon="getMarkerTypeIcon('cam')"
            :title="$t('map.markers.cam')">
            <FilterMarker v-for="marker in camMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="burrowMarkers.length > 0"
            :item-count="burrowMarkers.length"
            :icon="getMarkerTypeIcon('burrow')"
            :title="$t('map.markers.burrow')">
            <FilterMarker v-for="marker in burrowMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="parkingMarkers.length > 0"
            :item-count="parkingMarkers.length"
            :icon="getMarkerTypeIcon('parking')"
            :title="$t('map.markers.parking')">
            <FilterMarker v-for="marker in parkingMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="otherMarkers.length > 0"
            :item-count="otherMarkers.length"
            :icon="getMarkerTypeIcon('other')"
            :title="$t('map.markers.other')">
            <FilterMarker v-for="marker in otherMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>

          <FilterMarkerGroup
            v-if="customMarkers.length > 0"
            :item-count="customMarkers.length"
            :icon="getMarkerTypeIcon('custom')"
            :title="$t('map.markers.custom')">
            <FilterMarker v-for="marker in customMarkers" :key="marker.id" :marker="marker" />
          </FilterMarkerGroup>
        </FilterGroup>

        <FilterGroup
          v-if="trails.length > 0"
          :item-count="trails.length"
          :background-color="color('trails')"
          :title="$t('map.general.trails')"
          text-color="white"
          icon="icon-border">
          <FilterTrail v-for="trail in trails" :key="trail.id" :trail="trail" />
        </FilterGroup>

        <FilterGroup
          v-if="lines.length > 0"
          :item-count="lines.length"
          :background-color="color('lines')"
          :title="$t('map.general.lines')"
          text-color="white"
          icon="icon-border">
          <FilterLine v-for="line in lines" :key="line.id" :line="line" />
        </FilterGroup>

        <FilterGroup
          v-if="arrows.length > 0"
          :item-count="arrows.length"
          :background-color="color('arrows')"
          :title="$t('map.general.arrows')"
          text-color="white"
          icon="icon-border">
          <FilterArrow v-for="arrow in arrows" :key="arrow.id" :arrow="arrow" />
        </FilterGroup>
      </Fragment>
    </PanelContent>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import colors from '@/components/map/utils/colors'

import FilterGroup from './FilterGroup.vue'
import FilterSubArea from './FilterSubArea.vue'
import FilterTrail from './FilterTrail.vue'
import FilterLine from './FilterLine.vue'
import FilterArrow from './FilterArrow.vue'
import FilterMarkerGroup from './FilterMarkerGroup.vue'
import FilterMarker from './FilterMarker.vue'
import FilterBoundary from './FilterBoundary.vue'

export default {
  components: {
    FilterGroup,
    FilterSubArea,
    FilterTrail,
    FilterLine,
    FilterArrow,
    FilterMarkerGroup,
    FilterMarker,
    FilterBoundary
  },

  data () {
    return {
      searchText: ''
    }
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary'
    }),

    isActive: {
      get () { return this.$store.getters['map/isFilterActive'] },
      set (state) { return this.$store.dispatch('map/isFilterActive', state) }
    },

    markers () { return this.filterBySearchText('map/markers/markers') },
    subAreas () { return this.filterBySearchText('map/subAreas/getSubAreas') },
    forbiddenAreas () { return this.filterBySearchText('map/subAreas/getForbiddenAreas') },
    trails () { return this.filterBySearchText('map/trails/trails') },

    passMarkers () { return this.markersOfType('pass') },
    towerMarkers () { return this.markersOfType('tower') },
    foodPlaceMarkers () { return this.markersOfType('food_place') },
    baitMarkers () { return this.markersOfType('bait') },
    gatherPlaceMarkers () { return this.markersOfType('gathering_place') },
    bridgeMarkers () { return this.markersOfType('bridge') },
    fordMarkers () { return this.markersOfType('ford') },
    saltLickMarkers () { return this.markersOfType('salt_lick') },
    barMarkers () { return this.markersOfType('bar') },
    camMarkers () { return this.markersOfType('cam') },
    burrowMarkers () { return this.markersOfType('burrow') },
    parkingMarkers () { return this.markersOfType('parking') },
    otherMarkers () { return this.markersOfType('other') },
    customMarkers () { return this.markersOfType('custom') },

    lines () {
      const lines = this.filterBySearchText('map/lines/lines')
      return lines !== null ? lines.filter(line => line.lineType === 'line') : null
    },

    arrows () {
      const lines = this.filterBySearchText('map/lines/lines')
      return lines !== null ? lines.filter(line => line.lineType === 'arrow') : null
    },

    // afterSearchMarkers () { return this.markersOfType('aftersearch') },
    // observationMarkers () { return this.markersOfType('observation') },
    // pooMarkers () { return this.markersOfType('poo') },
    // killMarkers () { return this.markersOfType('kill') },

    isLoading () {
      return this.boundary === null || this.markers === null || this.subAreas === null || this.forbiddenAreas === null || this.lines === null || this.trails === null
    }
  },

  methods: {
    filterBySearchText (getter) {
      const objects = this.$store.getters[getter] // Will be null during load
      if (objects === null) {
        return null
      }

      if (this.searchText.length > 0) {
        return objects.filter(object => {
          const searchText = this.searchText.toLocaleLowerCase()

          let name = object.name || object.label // Lines use 'label' instead of 'name'
          name = name.toLocaleLowerCase()

          return name.indexOf(searchText) !== -1
        })
      }

      return objects
    },

    markersOfType (type) {
      return this.markers.filter(marker => marker.type === type)
    },

    getMarkerTypeIcon (markerType) {
      return getMarkerTypeIconUrl(markerType)
    },

    resetFilter () {
      this.searchText = ''
    },

    resetVisibility () {
      this.setObjectVisibility('map/subAreas/getSubAreas', true)
      this.setObjectVisibility('map/subAreas/getForbiddenAreas', true)
      this.setObjectVisibility('map/markers/markers', true)
      this.setObjectVisibility('map/trails/trails', true)
      this.setObjectVisibility('map/lines/lines', true) // Includes both lines and arrow
    },

    setObjectVisibility (getter, visibility) {
      const objects = this.$store.getters[getter] || []
      objects.forEach(object => {
        object.visible = visibility
      })
    },

    color (type) {
      return colors[type] || colors.default
    },

    close () {
      this.isActive = false
    }
  }
}
</script>

<style lang="scss">
.filter-panel label {
  font-size: 0.85rem;
}
</style>
