<template>
  <div v-if="isLoading" class="loading-panel">
    <span
      :class="{ 'is-hidden': !isLoading }"
      :style="{ 'width': progress }"
      class="loading-progress">
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isLoading: true
    }
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary',
      markers: 'map/markers/markers',
      subAreas: 'map/subAreas/getSubAreas',
      forbiddenAreas: 'map/subAreas/getForbiddenAreas'
    }),

    items () {
      return [this.boundary, this.markers, this.subAreas, this.forbiddenAreas]
    },

    progress () {
      let progress = 0

      this.items.forEach(item => {
        if (item !== null) {
          progress += 1 / this.items.length
        }
      })

      this.isLoaded(progress)

      return progress * 100 + '%'
    }
  },

  methods: {
    isLoaded (progress) {
      if (progress === 1) {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-progress {
  height: 1rem;
  width: 0%;

  opacity: 1;
  display: block;
  background: linear-gradient(25deg, #ffad6f, #db6e1d);
  box-shadow: 0 0.125rem 0.375rem 0 rgba(255, 255, 255, 0.55);

  transition: width, opacity 0.25s ease-out;
  will-change: width, opacity;

  &.is-hidden {
    opacity: 0;
    transition-duration: 0.15s;
  }
}
</style>
