<template>
  <ImportPanelGroup
    v-if="markers.length > 0"
    :backgroundColor="backgroundColor"
    :title="title"
    text-color="dark"
    @toggle-selection="toggleSelection">
    <ImportPanelGroupItem v-for="(marker, i) in markers" :key="i" :item="marker">
      <FormField>
        <TextInputField
          v-model="marker.name"
          :disabled="!marker.selected"
          size="small" />
      </FormField>

      <div>
        <div class="select is-small">
          <select v-model="marker.type" :disabled="!marker.selected">
            <option v-for="(markerType, j) in markerTypes" :key="j" :value="markerType.type">
              {{ markerType.name }}
            </option>
          </select>
        </div>
      </div>
    </ImportPanelGroupItem>
  </ImportPanelGroup>
</template>

<script>
import { mapGetters } from 'vuex'
import { markers } from '@/api/map/markers/markers'
import colors from '@/components/map/utils/colors'
import ImportPanelGroup from './ImportPanelGroup.vue'
import ImportPanelGroupItem from './ImportPanelGroupItem.vue'

export default {
  components: {
    ImportPanelGroup,
    ImportPanelGroupItem
  },

  computed: {
    ...mapGetters({
      markers: 'map/import/getMarkers'
    }),

    title () {
      return `${this.$i18n.t('map.import.markers')} (${this.markers.length})`
    },

    backgroundColor () {
      return colors['default']
    },

    markerTypes () {
      const unsupportedMarkerTypes = [
        'after_search',
        'observation',
        'kill',
        'poo',
        'tracks',
        'hunter',
        'hunter_safe',
        'hunter_off',
        'dog',
        'dog_off'
      ]

      return markers().filter(marker => !unsupportedMarkerTypes.includes(marker.type))
    }
  },

  methods: {
    toggleSelection (selected) {
      this.markers.forEach(marker => {
        marker.selected = selected
      })
    }
  }
}
</script>
