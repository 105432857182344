<template>
  <a
    :class="{ 'is-primary': isFilterActive }"
    class="button is-white"
    @click="toggleFilter">
    <Icon name="icon-filter" />
    <span v-if="!isMobile">{{ $t('map.tools.filter') }}</span>
  </a>
</template>

<script>
export default {
  computed: {
    isFilterActive: {
      get () { return this.$store.getters['map/isFilterActive'] },
      set (state) { return this.$store.dispatch('map/isFilterActive', state) }
    },

    isMobile () {
      return this.$mq.phone
    }
  },

  methods: {
    toggleFilter () {
      this.isFilterActive = !this.isFilterActive
    }
  }
}
</script>
