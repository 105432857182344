<template>
  <MapMarker
    :name="marker.name"
    :icon="icon"
    :backgroundIcon="markerBackgroundIcon"
    :label="markerLabel"
    :location="marker.location"
    :visible="marker.visible"
    :draggable="marker.editable"
    :animation="animation"
    clickable
    @click="onClick"
    @drag="onDrag">
  </MapMarker>
</template>

<script>
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import MapMarker from '@/modules/map/components/MapMarker.vue'

export default {
  components: {
    MapMarker
  },

  props: {
    marker: {
      type: Object,
      required: true
    }
  },

  computed: {
    icon () {
      return getMarkerTypeIconUrl(this.marker.type)
    },

    animation () {
      return this.selected ? 'bounce' : null
    },

    selected () {
      const selectedMarker = this.$store.getters['map/markers/selected']
      return this.marker === selectedMarker
    },

    markerLabel () {
      if (this.marker.type !== 'custom') {
        return null
      }

      return this.marker.customType
    },

    markerBackgroundIcon () {
      if (!this.marker.customColor) {
        return
      }

      return {
        path: 'M 0 -20 m -10 0 a 10,10 0 1,1 20,0 a 10,10 0 1,1 -20,0',
        strokeWeight: 0,
        fillOpacity: 1,
        fillColor: this.marker.customColor
      }
    }
  },

  methods: {
    onClick () {
      if (!this.selected) {
        this.$store.dispatch('map/markers/select', this.marker)

        this.$router.push({
          name: 'marker',
          params: {
            markerId: this.marker.id
          }
        })
      }
    },

    onDrag (position) {
      this.marker.location = position
    }
  }
}
</script>
