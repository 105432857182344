<template>
  <Panel :isActive="isActive" class="export-panel">
    <PanelTitle :back="false" @close="close">
      <span slot="title">{{ $t('map.export.title') }}</span>
    </PanelTitle>

    <PanelContent scrollable>
      <Alert v-if="hasError" type="danger">
        {{ $t('map.export.error') }}
      </Alert>

      <p v-html="$t('map.export.content1')"></p>
      <p v-html="$t('map.export.content2')"></p>

      <h6 class="title is-6">
        {{ $t('map.export.subtitle') }}
      </h6>

      <div v-if="hasBoundary" class="field">
        <div class="control">
          <Checkbox v-model="exportData.boundary" :disabled="isExporting" :label="$t('map.general.boundary')">
          </Checkbox>
        </div>
      </div>

      <div v-if="hasSubAreas" class="field">
        <div class="control">
          <Checkbox v-model="exportData.subAreas" :disabled="isExporting" :label="$t('map.general.subAreas')">
          </Checkbox>
        </div>
      </div>

      <div v-if="hasForbiddenAreas" class="field">
        <div class="control">
          <Checkbox v-model="exportData.forbiddenAreas" :disabled="isExporting" :label="$t('map.general.forbiddenAreas')">
          </Checkbox>
        </div>
      </div>

      <div v-if="hasMarkers" class="field">
        <div class="control">
          <Checkbox v-model="exportData.markers" :disabled="isExporting" :label="$t('map.general.markers')">
          </Checkbox>
        </div>
      </div>

      <div v-if="!hasBoundary && !hasSubAreas && !hasForbiddenAreas && !hasMarkers">
        {{ $t('map.export.noExport') }}
      </div>

      <div class="buttons push-top">
        <button :class="{ 'is-loading': isExporting }" :disabled="!canExport" class="button is-primary" @click="doExport">
          {{ $t('map.export.download') }}
        </button>
      </div>
    </PanelContent>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportDataModel from '@/api/map/gpx/exportDataModel'
// import gpx from '@/api/map/gpx/gpx.api'

export default {
  data () {
    return {
      exportData: new ExportDataModel(),
      state: ''
    }
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary',
      subAreas: 'map/subAreas/getSubAreas',
      forbiddenAreas: 'map/subAreas/getForbiddenAreas',
      markers: 'map/markers/markers'
    }),

    isActive: {
      get () { return this.$store.getters['map/isExportActive'] },
      set (state) { return this.$store.dispatch('map/isExportActive', state) }
    },

    isExporting () { return this.state === 'exporting' },
    hasError () { return this.state === 'error' },

    hasBoundary () { return this.boundary !== null && this.boundary.coordinates.length > 0 },
    hasSubAreas () { return this.subAreas !== null && this.subAreas.length > 0 },
    hasForbiddenAreas () { return this.forbiddenAreas !== null && this.forbiddenAreas.length > 0 },
    hasMarkers () { return this.markers !== null && this.markers.length > 0 },
    canExport () { return !this.exportData.isEmpty() && (this.hasBoundary || this.hasSubAreas || this.hasForbiddenAreas || this.hasMarkers) },
    area () { return this.$store.getters['huntarea/selected'] },
    areaName () { return this.area && this.area.name.toLowerCase() }
  },

  watch: {
    isActive (active) {
      if (active) {
        this.state = ''
        this.exportData = new ExportDataModel()
      }
    }
  },

  methods: {
    cancel () {
      this.close()
    },

    close () {
      this.isActive = false
    },

    async doExport () {
      this.state = 'exporting'
      this.$store.dispatch('ui/cursorLoader', true)

      try {
        const data = await this.$store.dispatch('map/gpx/export', {
          huntAreaId: this.$route.params.huntAreaId,
          exportData: this.exportData
        })

        const filename = this.areaName + '.gpx'
        const blob = new Blob([data], { type: 'application/octet-stream' }) // eslint-disable-line

        this.forceDownload(filename, blob)

        this.state = ''
        this.close()
      } catch (exception) {
        this.state = 'error'
      }

      this.$store.dispatch('ui/cursorLoader', false)
    },

    forceDownload (filename, blob) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const a = document.createElement('a')

        a.setAttribute('href', window.URL.createObjectURL(blob))
        a.setAttribute('download', filename)

        a.click()
      }
    }
  }
}
</script>

<style lang="scss">
.export-panel label {
  font-size: 0.85rem;
}
</style>
