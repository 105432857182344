<template>
  <ProgressModal
    v-if="isImporting"
    :progress="importProgress"
    :title="$t('map.import.importingData')">
  </ProgressModal>
</template>

<script>
import ProgressModal from '@/components/modals/ProgressModal.vue'

import { mapGetters } from 'vuex'
import { IMPORT_STATUS_SAVING } from '@/store/map/import/importStore.js'

export default {
  components: {
    ProgressModal
  },

  computed: {
    ...mapGetters({
      importProgress: 'map/import/getProgress'
    }),

    isImporting () {
      const importStatus = this.$store.getters['map/import/getStatus']
      return importStatus === IMPORT_STATUS_SAVING
    }
  }
}
</script>
