<template>
  <ImportPanelGroup
    v-if="subAreas.length > 0"
    :backgroundColor="backgroundColor"
    :title="title"
    text-color="light"
    @toggle-selection="toggleSelection">
    <ImportPanelGroupItem v-for="(subArea, i) in subAreas" :key="i" :item="subArea">
      <FormField>
        <TextInputField
          v-model="subArea.name"
          :disabled="!subArea.selected"
          size="small" />
      </FormField>
    </ImportPanelGroupItem>
  </ImportPanelGroup>
</template>

<script>
import { mapGetters } from 'vuex'
import colors from '@/components/map/utils/colors'
import ImportPanelGroup from './ImportPanelGroup.vue'
import ImportPanelGroupItem from './ImportPanelGroupItem.vue'

export default {
  components: {
    ImportPanelGroup,
    ImportPanelGroupItem
  },

  computed: {
    ...mapGetters({
      subAreas: 'map/import/getSubAreas'
    }),

    title () {
      return `${this.$i18n.t('map.import.subAreas')} (${this.subAreas.length})`
    },

    backgroundColor () {
      return colors['subArea']
    }
  },

  methods: {
    toggleSelection (selected) {
      this.subAreas.forEach(subArea => {
        subArea.selected = selected
      })
    }
  }
}
</script>
