<template>
  <div class="buttons is-marginless">
    <ToolbarFilterButton />
    <ToolbarPrintButton />
    <ToolbarImportButton />
    <ToolbarExportButton v-if="hasPro" />
    <SettingsButton />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ToolbarFilterButton from './ToolbarFilterButton.vue'
import ToolbarPrintButton from './ToolbarPrintButton.vue'
import ToolbarImportButton from './ToolbarImportButton.vue'
import ToolbarExportButton from './ToolbarExportButton.vue'

export default {
  components: {
    ToolbarFilterButton,
    ToolbarPrintButton,
    ToolbarImportButton,
    ToolbarExportButton
  },

  computed: {
    ...mapGetters({
      hasPro: 'subscription/hasPro'
    })
  }
}
</script>
