<script>
import LabelOverlay from '@/components/map/overlays/labelOverlay'
import colors from '@/components/map/utils/colors'

export default {
  props: {
    area: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data () {
    return {
      $map: null,
      $polygon: null,
      $label: null
    }
  },

  computed: {
    selected () {
      return this.area.selected
    },

    name () {
      return this.area.name
    }
  },

  watch: {
    selected (selected) {
      if (this.$polygon) {
        this.$polygon.setVisible(selected)
        this.updateLabelVisibility()
      }
    },

    showForbiddenLabels () {
      this.updateLabelVisibility()
    },

    name (name) {
      this.$label.label = name
    }
  },

  async mounted () {
    this.$map = await this.$parent.getMap()

    const bounds = this.getBounds()

    this.$label = new LabelOverlay({
      position: bounds.getCenter(),
      text: this.area.name,
      map: this.$map,
      class: 'subarea-label-overlay'
    })

    const polygon = new window.google.maps.Polygon({
      map: this.$map,
      paths: this.area.borderCoordinates,
      clickable: false,
      strokeColor: colors['forbiddenArea'],
      strokeWeight: 2,
      strokeOpacity: 1,
      fillOpacity: 0.5,
      fillColor: colors['forbiddenArea']
    })

    if (polygon) {
      this.$polygon = polygon
    } else {
      console.error('Failed to create import forbidden area.')
    }

    this.updateLabelVisibility()
  },

  destroyed () {
    if (this.$polygon) {
      this.$label.setMap(null)
      this.$polygon.setMap(null)
    }
  },

  render () {
    return ''
  },

  methods: {
    getBounds () {
      const bounds = new window.google.maps.LatLngBounds()
      const coords = this.area.borderCoordinates

      coords.forEach(coord => bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng)))

      return bounds
    },

    updateLabelVisibility () {
      const showLabels = this.$store.getters['map/subAreas/showForbiddenLabels']
      this.$label.setMap((showLabels && this.area.selected ? this.$map : null))
    }
  }
}
</script>
