<template>
  <MapMarker
    :name="marker.name"
    :icon="icon"
    :backgroundIcon="markerBackgroundIcon"
    :label="markerLabel"
    :location="marker.location"
    :visible="marker.selected">
  </MapMarker>
</template>

<script>
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import MapMarker from '@/modules/map/components/MapMarker.vue'

export default {
  components: {
    MapMarker
  },

  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    icon () {
      return getMarkerTypeIconUrl(this.marker.type)
    },

    markerLabel () {
      if (this.marker.type !== 'custom') {
        return null
      }

      return this.marker.customType
    },

    markerBackgroundIcon () {
      if (!this.marker.customColor) {
        return
      }

      return {
        path: 'M 0 -20 m -10 0 a 10,10 0 1,1 20,0 a 10,10 0 1,1 -20,0',
        strokeWeight: 0,
        fillOpacity: 1,
        fillColor: this.marker.customColor
      }
    }
  }
}
</script>
