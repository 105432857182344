<script>
import colors from '@/components/map/utils/colors'

export default {
  props: {
    area: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data () {
    return {
      $map: null,
      $polygon: null
    }
  },

  computed: {
    selected () {
      return this.area.selected
    }
  },

  watch: {
    selected (selected) {
      if (this.$polygon) {
        this.$polygon.setVisible(selected)
      }
    }
  },

  async mounted () {
    this.$map = await this.$parent.getMap()

    const polygon = new window.google.maps.Polygon({
      map: this.$map,
      paths: this.area.borderCoordinates,
      clickable: false,
      strokeColor: colors['boundary'],
      strokeWeight: 4,
      strokeOpacity: 1,
      fillOpacity: 0
    })

    if (polygon) {
      this.$polygon = polygon
      this.$map.fitBounds(this.getBounds())
    } else {
      console.error('Failed to create import-boundary')
    }
  },

  destroyed () {
    if (this.$polygon) {
      this.$polygon.setMap(null)
    }
  },

  render () {
    return ''
  },

  methods: {
    getBounds () {
      const bounds = new window.google.maps.LatLngBounds()
      const coords = this.area.borderCoordinates

      coords.forEach(coord => bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng)))

      return bounds
    }
  }
}
</script>
