<template>
  <Fragment>
    <Button
      v-if="hasMarkers"
      type="danger"
      class="is-fullwidth"
      @click="confirmRemove">
      <span class="is-size-7">{{ $t('map.settings.removeAllMarkers') }}</span>
    </Button>

    <ProgressModal
      v-if="isDeleting"
      :progress="progress"
      :title="$t('map.settings.removingMarkers')">
    </ProgressModal>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import ProgressModal from '@/components/modals/ProgressModal.vue'

export default {
  components: {
    ProgressModal
  },

  data () {
    return {
      isDeleting: false,
      totalMarkers: 0,
      markersDeleted: 0
    }
  },

  computed: {
    ...mapGetters({
      markers: 'map/markers/markers'
    }),

    hasMarkers () {
      return this.markers && this.markers.length > 0
    },

    progress () {
      return Math.floor(this.markersDeleted / this.totalMarkers * 100)
    }
  },

  methods: {
    async confirmRemove () {
      try {
        const response = await this.$removeDialog.confirm()

        if (response.ok) {
          await this.removeAllMarkers()
        }
      } catch (error) {
        this.$notification.danger(this.$i18n.t('map.settings.removeMarkersFailed'))
        console.error(error)
      }
    },

    async removeAllMarkers () {
      const huntArea = this.$store.getters['huntarea/selected']
      const markers = this.markers.slice(0) // Make copy or iteration will break once we start removing

      this.totalMarkers = this.markers.length
      this.markersDeleted = 0
      this.isDeleting = true

      let failedCount = 0

      for (const marker of markers) {
        try {
          await this.$store.dispatch('map/markers/delete', {
            huntAreaId: huntArea.id,
            marker: marker
          })
        } catch (error) {
          console.error(error)
          failedCount++
        } finally {
          this.markersDeleted++
        }
      }

      this.isDeleting = false

      if (failedCount > 0) {
        throw new Error('One or more markers could not be removed. See earlier log entries for more info.')
      }
    }
  }
}
</script>
