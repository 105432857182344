const tools = [{
  name: 'map.general.tools.addMarker',
  path: 'addMarker',
  icon: 'icon-map-pin'
}, {
  name: 'map.general.tools.addSubArea',
  path: 'addSubArea',
  icon: 'icon-plus'
}, {
  name: 'map.general.tools.editBoundary',
  path: 'toggleEditBoundary',
  icon: 'icon-edit'
}, {
  name: 'map.general.tools.addForbiddenArea',
  path: 'addForbiddenArea',
  icon: 'icon-alert-triangle'
}]

const links = [
  // {
  //   name: 'Importera',
  //   path: 'import',
  //   icon: 'icon-upload'
  // },
  {
    name: 'map.general.export',
    path: 'export',
    icon: 'icon-download'
  }
]

export {
  tools,
  links
}
