<template>
  <div :class="[className]" class="drawing-panel">
    <div class="columns">
      <div class="column">
        <p
          v-for="(mode, i) in modes"
          v-show="drawingMode === mode"
          :key="i"
          class="is-marginless"
          v-html="content[mode]">
        </p>
      </div>

      <div class="column is-narrow">
        <button class="button is-pulled-right" @click="cancel">
          {{ $t('general.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      modes: ['marker', 'subArea', 'forbiddenArea', 'line', 'arrow']
    }
  },

  computed: {
    ...mapGetters({
      drawingMode: 'map/getDrawingMode'
    }),

    className () {
      return this.drawingMode !== null ? 'is-active' : ''
    },

    content () {
      return {
        marker: this.$t('map.general.notification.marker'),
        subArea: this.$t('map.general.notification.subArea'),
        forbiddenArea: this.$t('map.general.notification.forbiddenArea'),
        line: this.$t('map.general.notification.line'),
        arrow: this.$t('map.general.notification.arrow')
      }
    }
  },

  methods: {
    cancel () {
      this.$store.dispatch('map/setDrawingMode', null)
    }
  }
}
</script>

<style lang="scss" scoped>
.drawing-panel {
  background: rgba(#42555b, 0.95);
  color: white;
  padding: 1rem;
  border-radius: 0.25rem;
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  left: 4.5rem + 1.5rem;
  z-index: 10;
  touch-action: none; // disable pinch-to-zoom iOS

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }

  &.push-left {
    left: 3rem + 17.5rem + 1rem;
    margin-left: 0;
    margin-right: 0;
  }

  .columns .column {
    display: flex;
    align-items: center;

    &.is-narrow {
      align-items: center;
      display: flex;
    }

    p {
      margin-bottom: 0;
    }
  }
}
</style>
