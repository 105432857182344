<template>
  <div class="import-item">
    <div>
      <Checkbox v-model="item.selected" class="checkbox-wrap" />
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.checkbox-wrap {
  padding-left: 0.25rem;
}

.import-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.import-item:last-child {
  margin-bottom: 0;
}

.import-item > div:first-of-type {
  margin: 0 0.5rem 0 0;
}

.import-item > div:last-of-type {
  margin: 0 0 0 0.5rem;
}

.import-item > .field {
  margin-bottom: 0 !important;
  flex-grow: 1;
}
</style>
