<template>
  <h5 class="title is-5 is-marginless has-text-black">
    <div v-if="huntArea">{{ huntAreaName }}</div>
    <span v-if="hasActiveHunt" class="tag is-primary push-top-xxs">{{ $t('map.general.hasActiveHunt') }}</span>
  </h5>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      hasActiveHunt: 'huntarea/hasActiveHunt'
    }),

    huntAreaName () {
      return this.$utils.capitalize(this.huntArea.name)
    }
  }
}
</script>
