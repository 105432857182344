<template>
  <a
    :class="{ 'is-primary': isImportActive }"
    :title="$t('map.tools.import')"
    class="button is-white"
    @click="toggleImport">
    <Icon name="icon-upload" />
  </a>
</template>

<script>
export default {
  computed: {
    hasPro () {
      return this.$store.getters['subscription/hasPro']
    },

    isImportActive: {
      get () { return this.$store.getters['map/isImportActive'] },
      set (state) { return this.$store.dispatch('map/isImportActive', state) }
    }
  },

  methods: {
    toggleImport () {
      this.isImportActive = !this.isImportActive
    }
  }
}
</script>
