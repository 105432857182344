class ExportDataModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.boundary = data.boundary || true
    this.subAreas = data.subAreas || true
    this.forbiddenAreas = data.forbiddenAreas || true
    this.markers = data.markers || true
  }

  isEmpty () {
    return this.boundary === false && this.subAreas === false && this.forbiddenAreas === false && this.markers === false
  }
}

export default ExportDataModel
