<template>
  <ImportPanelGroup
    v-if="forbiddenAreas.length > 0"
    :backgroundColor="backgroundColor"
    :title="title"
    text-color="light"
    @toggle-selection="toggleSelection">
    <ImportPanelGroupItem v-for="(forbiddenArea, i) in forbiddenAreas" :key="i" :item="forbiddenArea">
      <FormField>
        <TextInputField
          v-model="forbiddenArea.name"
          :disabled="!forbiddenArea.selected"
          size="small" />
      </FormField>
    </ImportPanelGroupItem>
  </ImportPanelGroup>
</template>

<script>
import { mapGetters } from 'vuex'
import colors from '@/components/map/utils/colors'
import ImportPanelGroup from './ImportPanelGroup.vue'
import ImportPanelGroupItem from './ImportPanelGroupItem.vue'

export default {
  components: {
    ImportPanelGroup,
    ImportPanelGroupItem
  },

  computed: {
    ...mapGetters({
      forbiddenAreas: 'map/import/getForbiddenAreas'
    }),

    title () {
      return `${this.$i18n.t('map.import.forbiddenAreas')} (${this.forbiddenAreas.length})`
    },

    backgroundColor () {
      return colors['forbiddenArea']
    }
  },

  methods: {
    toggleSelection (selected) {
      this.forbiddenAreas.forEach(forbiddenArea => {
        forbiddenArea.selected = selected
      })
    }
  }
}
</script>
