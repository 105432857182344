<template>
  <div v-if="isVisible">
    <div class="zoom-level-notification">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <Icon class="exclamation" name="icon-alert-circle" />
          </div>

          <div class="level-item">
            <div v-html="$t('map.zoomLevelNotSupportedNotification')" />
          </div>
        </div>

        <div class="level-right">
          <Icon
            class="close"
            name="icon-x"
            @click="close">
          </Icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PROPERTY_LAYER_MAX_ZOOM } from '@/components/map/layers/propertyLayer'
import { TERRAIN_LAYER_MAX_ZOOM } from '@/components/map/layers/terrainLayer'

export default {
  data () {
    return {
      isVisible: false
    }
  },

  computed: {
    ...mapGetters({
      currentZoom: 'map/settings/zoom',
      isTerrainLayerVisible: 'map/settings/isTerrainLayerVisible',
      isPropertyLayerVisible: 'map/settings/isPropertyLayerVisible',
      drawingMode: 'map/getDrawingMode'
    })
  },

  watch: {
    currentZoom () {
      this.updateVisibility()
    },

    isTerrainLayerVisible () {
      this.updateVisibility()
    },

    isPropertyLayerVisible () {
      this.updateVisibility()
    },

    drawingMode () {
      if (this.isVisible && this.drawingMode !== null) {
        this.close()
      }
    }
  },

  methods: {
    updateVisibility () {
      if (this.isTerrainLayerVisible && this.currentZoom > TERRAIN_LAYER_MAX_ZOOM) {
        this.isVisible = true
      } else if (this.isPropertyLayerVisible && this.currentZoom > PROPERTY_LAYER_MAX_ZOOM) {
        this.isVisible = true
      } else {
        this.isVisible = false
      }
    },

    close () {
      this.isVisible = false
    }
  }
}
</script>

<style stoped>
.zoom-level-notification {
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 0.75rem;
  background-color: white;
  border-radius: 4px;
  border-left: 4px solid #3298dc;
  border-top: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}

@media screen and (max-width: 1024px) {
  .zoom-level-notification {
    left: 1.5rem;
    right: 1.5rem;
    transform: none;
  }
}

.zoom-level-notification .exclamation {
  color: #3298dc;
  margin-right: 1rem;
}

.zoom-level-notification .close {
  color: black;
  margin-left: 1rem;
  cursor: pointer;
}
</style>
