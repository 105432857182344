/* eslint-disable no-undef */
import html2canvas from 'html2canvas'
import * as JsPDF from 'jspdf'
import { eventManager } from '@/main'
import store from '@/store/map/print/'
import mapOptions from './mapOptions'
import * as _ from '@/assets/libs/utils/'

class Print extends window.google.maps.OverlayView {
  constructor (map, options) {
    super()
    Object.assign(this, options)
    this.map = map
    this.mapDiv = document.querySelector('#map')
    this.mapContainer = document.querySelector('#map-container')
    this.printDiv = document.querySelector('#map > div:first-of-type')

    this.points = {}

    this.propertyLayerVisible = options.propertyLayerVisible || false

    this.setMap(this.map)
    this.listeners()
    this.doPrint()
  }

  onAdd () {
    // Don't do anything for now
  }

  onRemove () {
    // Don't do anything for now
  }

  beforePrint () {
    // Remove map controls for now
    this.map.setOptions({
      scaleControl: false,
      zoomControl: false,
      fullscreenControl: false
    })

    // Save current map center and zoom level
    // So we can restore map after printing
    this.backup = {
      mapCenter: this.map.getCenter(),
      mapZoom: this.map.getZoom()
    }
  }

  getPoints () {
    const overlay = document.querySelector('#print-preview')
    const { top, left, width, height } = window.getComputedStyle(overlay, null)

    const num = x => parseFloat(x.replace('px', ''))

    // Get print overlay window coordinates
    const topLeft = new window.google.maps.Point(
      Math.round(num(left)),
      Math.round(num(top))
    )

    const bottomRight = new window.google.maps.Point(
      Math.round(num(left) + num(width)),
      Math.round(num(top) + num(height))
    )

    const centerPoint = new window.google.maps.Point(
      Math.round(num(left) + (num(width) / 2)),
      Math.round(num(top) + (num(height) / 2))
    )

    return {
      topLeft,
      bottomRight,
      centerPoint
    }
  }

  doPrint () {
    this.beforePrint()
    eventManager.$emit('map:printStart')

    // We cannot rely on timeouts for making sure the map is rendered, but timeouts are a pretty
    // easy way to give the browser time to breathe once in a while. So, we begin by giving
    // the print:start event above one second to kick in.
    setTimeout(() => {
      // Create a bounding box for the print preview
      this.projection = this.getProjection()
      const { topLeft, bottomRight, centerPoint } = this.getPoints()

      // Transform print overlay coordinates to lat/lng
      this.points.topLeft = this.projection.fromContainerPixelToLatLng(topLeft)
      this.points.bottomRight = this.projection.fromContainerPixelToLatLng(bottomRight)
      this.points.centerPoint = this.projection.fromContainerPixelToLatLng(centerPoint)

      // Create a bounding box for the print preview
      this.bounds = new window.google.maps.LatLngBounds()
      this.bounds.extend(this.points.topLeft)
      this.bounds.extend(this.points.bottomRight)

      this.fitPaper(true)

      // // This will be triggered after we call fitBounds below, even if bounds are not changed (since Google Maps v3.32)
      window.google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
        google.maps.event.trigger(this.map, 'resize') // Trigger a resize event, will cause map to be redrawn

        console.log('doPrint: loading tiles')

        const checkTilesReady = () => {
          // Now, we need to wait for all map tiles to finish loading - idle/tilesloaded map events do not account for custom overlays.
          // The getTile function in each custom overlay has been overloaded to implement simple reference counting of tile URLs.
          // console.log('doPrint: checking tile status')

          if (store.state.busyLayers.length) {
            console.log(`doPrint: ${store.state.busyLayers.length} busy layers...`)
            console.log(store.state.busyLayers)
            // console.log(this.pendingTiles)
            setTimeout(checkTilesReady, 7000)
            return
          }

          // console.log('doPrint: all tiles have loaded')

          // All tiles seem to have loaded, give the map a few seconds to render and then proceed
          setTimeout(() => {
            // fitBounds always leaves a margin. Calculate the size of that margin and resize the map div accordingly.
            const { x, y } = this.projection.fromLatLngToContainerPixel(this.points.topLeft)

            let w = parseFloat(window.getComputedStyle(this.mapContainer, null).getPropertyValue('width').replace('px', ''))
            let h = parseFloat(window.getComputedStyle(this.mapContainer, null).getPropertyValue('height').replace('px', ''))

            if (w && h) {
              w -= x * 2
              h -= y * 2

              this.mapDiv.style.width = w + 'px'
              this.mapDiv.style.height = h + 'px'

              this.mapContainer.style.width = this.mapDiv.style.width
              this.mapContainer.style.height = this.mapDiv.style.height
            }

            // This will be triggered once the map has been re-centered
            google.maps.event.addListenerOnce(this.map, 'idle', () => {
              // We're good to go, again give the map a few seconds to render and finally create the PDF.
              const printReady = () => {
                if (store.state.busyLayers.length) {
                  console.log('printReady: waiting for tiles...')
                  console.log(store.state.busyLayers)
                  setTimeout(printReady, 7000)
                  return
                }

                this.snapshot()
              }

              setTimeout(printReady, 7000)
            }) // idle

            // Make sure the map is correctly centered again
            this.map.setCenter(this.points.centerPoint)
            google.maps.event.trigger(this.map, 'resize')
          }, 3000)
        } // checkTilesReady()

        setTimeout(checkTilesReady, 7000)
      })

      this.map.fitBounds(this.bounds) // Fit bounds to print preview
    }, 1000)
  }

  async snapshot () {
    try {
      const canvas = await html2canvas(document.querySelector('.gm-style'), {
        logging: !!process.env.VUE_APP_SILENT,
        backgroundColor: null,
        useCORS: true,
        async: false,
        imageTimeout: 0,
        scale: 2
      })

      // An A0 sized PNG would consume crazy amounts of RAM, use 80% JPEG compression
      const base64 = this.toJPEG(canvas, 80)
      this.toPDF(base64, 'JPEG')

      this.afterPrint()
    } catch (error) {
      console.error(error)
    }
  }

  toPNG (canvas) {
    return canvas.toDataURL('image/png')
  }

  toJPEG (canvas, compression = 100) {
    return canvas.toDataURL('image/jpeg', compression)
  }

  toPDF (img, imgType) {
    const { width, height } = mapOptions[this.paperSize][this.orientation]

    const doc = new JsPDF({
      orientation: this.orientation,
      unit: 'mm',
      format: this.paperSize
    })

    doc.addImage(img, imgType, 0, 0, width, height)

    // Add Logo
    const whLogo = new Image()
    whLogo.src = '/img/logos/logo-text-black.png'
    doc.addImage(whLogo, 'PNG', width - 55, height - 20, 45, 10)

    // Add Area Name
    doc.setFontSize(20)

    doc.setLineWidth(1)
    doc.setDrawColor('#ffffff')
    doc.text(_.capitalize(this.name), 10, 10, { baseline: 'top', renderingMode: 'stroke' })

    doc.setDrawColor('#000000')
    doc.text(_.capitalize(this.name), 10, 10, { baseline: 'top', renderingMode: 'fill' })

    // Add Fastighetskarta Logo
    if (this.propertyLayerVisible) {
      const lmLogo = new Image()
      lmLogo.src = '/img/logos/lantmateriet.png'
      doc.addImage(lmLogo, 'PNG', 10, height - 40, 54, 50)
    }

    doc.save(`${_.slug(this.name)}.pdf`)
  }

  fitPaper (state) {
    if (state) {
      const { width, height } = mapOptions[this.paperSize][this.orientation]

      // Scale the paper up a bit to account for fitbounds margin
      this.mapDiv.style.width = (width * 1.5) + 'mm'
      this.mapDiv.style.height = (height * 1.5) + 'mm'
    } else {
      this.mapDiv.style.width = ''
      this.mapDiv.style.height = ''
    }

    this.mapContainer.style.width = this.mapDiv.style.width
    this.mapContainer.style.height = this.mapDiv.style.height
  }

  afterPrint () {
    this.map.setCenter(this.backup.mapCenter)
    this.map.setZoom(this.backup.mapZoom)
    this.map.setOptions({
      scaleControl: true,
      zoomControl: false,
      fullscreenControl: false
    })

    this.fitPaper(false)
    eventManager.$emit('map:printEnd')
  }

  listeners () {
    if (this.propertyLayerVisible) {
      eventManager.$on('map:printStart', () => {
        // console.log('Clearing busyLayers and pendingTiles')
        store.state.skipTileCache = true
        store.state.busyLayers = []

        for (const key of Object.keys(store.state.pendingTiles)) {
          store.state.pendingTiles[key] = []
        }
      })

      eventManager.$on('map:printEnd', () => {
        store.state.skipTileCache = false
      })
    }
  }
}

export default Print
