<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'polygon'
    },

    borderColor: {
      type: String,
      default: 'black'
    },

    borderWidth: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      $map: null,
      $drawingManager: null,
      mode: 'polygon'
    }
  },

  watch: {
    active () {
      if (this.active) {
        // this.$router.push(`/area/${this.huntAreaId}/map`)

        const drawingModes = {
          marker: window.google.maps.drawing.OverlayType.MARKER,
          polygon: window.google.maps.drawing.OverlayType.POLYGON,
          line: window.google.maps.drawing.OverlayType.POLYLINE,
          arrow: window.google.maps.drawing.OverlayType.POLYLINE
        }

        this.mode = drawingModes[this.type] || 'polygon'

        this.$drawingManager.setOptions({
          polygonOptions: {
            clickable: true,
            editable: true,
            strokeOpacity: 1,
            fillOpacity: 0,
            strokeColor: this.borderColor,
            strokeWeight: this.borderWidth
          }
        })

        this.$drawingManager.setDrawingMode(this.mode)
        this.$drawingManager.setMap(this.$map)
      } else {
        this.$drawingManager.setDrawingMode(null)
        this.$drawingManager.setMap(null)
      }
    }
  },

  async mounted () {
    this.$map = await this.$parent.getMap()

    this.$drawingManager = new window.google.maps.drawing.DrawingManager({
      map: this.$map,
      drawingControl: false
    })

    // Marker
    window.google.maps.event.addListener(this.$drawingManager, 'markercomplete', marker => {
      if (this.active) {
        this.$emit('marker-created', marker.getPosition().toJSON())
      }

      marker.setMap(null)
    })

    // Polygon
    window.google.maps.event.addListener(this.$drawingManager, 'polygoncomplete', polygon => {
      if (this.active) {
        let coords = []

        polygon.getPath().forEach(coord => {
          coords.push(coord.toJSON())
        })

        this.$emit('polygon-created', coords)
      }

      polygon.setMap(null)
    })

    // Polyline
    window.google.maps.event.addListener(this.$drawingManager, 'overlaycomplete', e => {
      const line = e.overlay

      if (this.active && this.mode === 'polyline') {
        let coords = []

        line.getPath().forEach(coord => {
          coords.push(coord.toJSON())
        })

        this.$emit('line-created', {
          coords: coords,
          type: this.type
        })
      }

      line.setMap(null)
    })
  },

  render () {
    return ''
  }
}
</script>
