<template>
  <div :style="{ border: borderStyle }" class="layer-group">
    <div
      :style="style"
      class="layer-group-header"
      @click="toggleExpand">
      <div>
        <i
          :class="eye"
          class="icon"
          @click.stop.prevent="toggleVisibility">
        </i>

        <span :class="{ 'is-dimmed': !isVisible }" class="layer-group-title">
          <span>{{ title }}</span>
          <small v-show="itemCount !== null">&nbsp;({{ itemCount }})</small>
        </span>
      </div>

      <Icon :name="chevron" class="is-clickable" />
    </div>

    <div v-show="hasExpanded">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    backgroundColor: {
      type: String,
      default: 'lightgrey'
    },

    textColor: {
      type: String,
      default: 'black'
    },

    icon: {
      type: String,
      default: ''
    },

    itemCount: {
      type: Number,
      default: null
    },

    isExpanded: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isVisible: true,
      hasExpanded: this.isExpanded,
      borderStyle: '1px solid ' + this.backgroundColor
    }
  },

  computed: {
    style () {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor
      }
    },

    eye () {
      return this.isVisible ? 'icon-eye' : 'icon-eye-off'
    },

    chevron () {
      return this.hasExpanded ? 'icon-chevron-up' : 'icon-chevron-down'
    },

    hasVisibleChildren () {
      for (let i = 0; i < this.$children.length; i++) {
        const child = this.$children[i]

        if (child.isVisible !== undefined) {
          if (child.isVisible) {
            return true
          }
        }
      }

      return false
    }
  },

  methods: {
    toggleVisibility () {
      this.isVisible = !this.isVisible

      this.$children.forEach(child => {
        if (child.setVisible !== undefined) {
          child.setVisible(this.isVisible)
        }
      })
    },

    updateVisibility () {
      this.isVisible = this.hasVisibleChildren
    },

    toggleExpand () {
      this.hasExpanded = !this.hasExpanded
    }
  }
}
</script>

<style lang="scss">
.layer-group {
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  background-color: white;
  margin-bottom: 0.5rem;
}

.layer-group-header {
  cursor: pointer;
  background-color: #dde4e2;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0.25rem;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .title {
    font-size: 0.85rem;
  }
}

.layer-group-title {
  padding-left: 0.275rem;
  font-size: 0.85rem;
}

.layer-group-item {
  cursor: pointer;
  color: black;
  padding: 0.25rem;
  font-size: 0.85rem;

  &:hover {
    background: aliceblue;
  }
}

.layer-group-button {
  padding: 0.25rem;
}
</style>
