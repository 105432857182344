<template>
  <div
    :class="[filterClass]"
    class="layer-marker"
    @click="centerOn">
    <div class="flex align-center">
      <i
        :class="icon"
        class="icon"
        @click.stop.prevent="toggleVisibility">
      </i>

      <span class="title">{{ marker.name }}</span>
    </div>

    <span @click.stop.prevent="select(!isSelected)">
      <i class="icon icon-edit" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    marker: {
      type: Object,
      default: () => {
        return {
          name: ''
        }
      }
    }
  },

  computed: {
    isSelected () {
      return this.$store.getters['map/markers/selected'] === this.marker
    },

    isVisible () {
      return this.marker.visible
    },

    filterClass () {
      return {
        'is-dimmed': !this.isVisible,
        'is-selected': this.isSelected
      }
    },

    icon () {
      return this.isVisible ? 'icon-eye' : 'icon-eye-off'
    }
  },

  watch: {
    isVisible () {
      if (this.$parent.updateVisibility !== undefined) {
        this.$parent.updateVisibility()
      }
    }
  },

  methods: {
    centerOn () {
      this.$store.commit('map/setCenter', this.marker.location)
    },

    async select (selected) {
      await this.$store.dispatch('map/select', {
        type: 'markers',
        data: selected ? this.marker : null
      })

      this.showPanel()
      this.centerOn()
    },

    showPanel () {
      this.$router.push({
        name: 'marker',
        params: {
          markerId: this.marker.id
        }
      })
    },

    toggleVisibility () {
      this.setVisible(!this.isVisible)
    },

    setVisible (visibility) {
      this.marker.visible = visibility
    }
  }
}
</script>

<style lang="scss">
.layer-marker {
  cursor: pointer;
  padding: 0.25rem 0.25rem 0.25rem 2rem;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: aliceblue;
  }

  &.is-selected {
    background: lemonchiffon;
  }

  .title {
    font-size: 0.85rem;
    font-weight: normal;
    padding-left: 0.25rem;
    margin-bottom: 0 !important;
  }
}
</style>
