<template>
  <div v-if="hasMetaData" class="content is-small">
    <ul class="import-meta">
      <li v-if="metaData.creator !== null">
        <Icon name="icon-map" />
        <span v-html="$t('map.import.application', { name: creator })" />
      </li>

      <li v-if="metaData.author !== null">
        <Icon name="icon-user" />
        <span v-html="$t('map.import.createdBy', { name: metaData.author })" />
      </li>

      <li v-if="metaData.createdAt !== null">
        <Icon name="icon-calendar" />
        <span v-html="$t('map.import.createdAt', { date: createdAt })" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      metaData: 'map/import/getMetaData'
    }),

    hasMetaData () {
      return this.metaData !== null
    },

    creator () {
      return this.metaData.creator.startsWith('WeHunt') ? 'WeHunt' : this.metaData.creator
    },

    createdAt () {
      return this.$dayjs(this.metaData.createdAt).format('DD/MM/YY')
    }
  }
}
</script>

<style scoped>
.import-meta {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
