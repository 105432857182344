<template>
  <Fragment>
    <Titlebar class="position-is-absolute">
      <MapTitle slot="title" />
      <Toolbar slot="local" />
    </Titlebar>

    <div :class="[safari]" class="map-wrap">
      <Loader v-if="isLoading || isPrinting || isDeletingOutsideBorder" size="large">
        <p>{{ isPrinting ? $t('map.general.printLoadingMessage') : '' }}</p>
      </Loader>

      <Fragment v-if="!isLoading">
        <Toolbox />
        <Panels :isAdmin="isAdmin" :map="map" />
        <Notification />
        <ZoomLevelNotification />

        <SettingsPanel type="advanced">
          <article v-if="hasMarkers && isAdmin" class="message is-danger push-top-xl">
            <div class="message-body">
              <RemoveAllMarkers />
              <RemoveMarkersOutsideBoundary />
            </div>
          </article>
        </SettingsPanel>
      </Fragment>

      <GoogleMaps
        id="map-container"
        :class="{ 'is-disabled': isLoading }"
        :showPropertyBoundaries="showPropertyBoundaries"
        print
        @on-initialized="onMapInitialized">
        <ZoomButtons />
        <BoundaryOverlay v-if="boundary" />

        <MapMarker
          v-for="marker in markers"
          :key="marker.id"
          :marker="marker">
        </MapMarker>

        <SubAreaOverlay
          v-for="subArea in subAreas"
          :key="subArea.id"
          :data="subArea"
          :border-width="2"
          :clickable="true"
          :fill-color="color('subArea')"
          :border-color="color('subArea')">
        </SubAreaOverlay>

        <SubAreaOverlay
          v-for="forbiddenArea in forbiddenAreas"
          :key="forbiddenArea.id"
          :data="forbiddenArea"
          :border-width="2"
          :fill-opacity="0.5"
          :clickable="true"
          :border-color="color('forbiddenArea')"
          :fill-color="color('forbiddenArea')">
        </SubAreaOverlay>

        <TrailOverlay
          v-for="trail in trails"
          :key="trail.id"
          :trail="trail">
        </TrailOverlay>

        <LineOverlay
          v-for="line in lines"
          :key="line.id"
          :data="line">
        </LineOverlay>

        <DrawingOverlay
          :active="drawingMode !== null"
          :type="drawingMode"
          :border-color="drawingOptions.borderColor"
          :border-width="drawingOptions.borderWidth"
          @marker-created="addMarker"
          @polygon-created="addPolygon"
          @line-created="addLine">
        </DrawingOverlay>

        <!-- Data shown during ongoing import -->
        <Fragment v-if="isImporting">
          <ImportBoundary
            v-for="(area, i) in importBoundaries"
            :key="`import-boundary-${i}`"
            :area="area">
          </ImportBoundary>

          <ImportSubArea
            v-for="(area, j) in importSubAreas"
            :key="`import-subarea-${j}`"
            :area="area">
          </ImportSubArea>

          <ImportForbiddenArea
            v-for="(area, k) in importForbiddenAreas"
            :key="`import-forbidden-area-${k}`"
            :area="area">
          </ImportForbiddenArea>

          <ImportMarker
            v-for="(marker, i) in importMarkers"
            :key="`marker-${i}`"
            :marker="marker">
          </ImportMarker>
        </Fragment>
      </GoogleMaps>
    </div>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventManager } from '@/main'
import { RemoveAllMarkers, RemoveMarkersOutsideBoundary } from './settings/'
import { MapTitle, Toolbar, Notification, Toolbox, Panels } from './components/'

import DrawingOverlay from './overlays/DrawingOverlay.vue'
import ZoomLevelNotification from './components/ZoomLevelNotification.vue'

import ImportMarker from './import/ImportMarker.vue'
import ImportBoundary from './import/ImportBoundary.vue'
import ImportSubArea from './import/ImportSubArea.vue'
import ImportForbiddenArea from './import/ImportForbiddenArea.vue'

import MapMarker from './MapMarker.vue'

import colors from '@/components/map/utils/colors'

export default {
  components: {
    RemoveAllMarkers,
    RemoveMarkersOutsideBoundary,

    DrawingOverlay,
    ZoomLevelNotification,

    ImportBoundary,
    ImportMarker,
    ImportSubArea,
    ImportForbiddenArea,

    MapTitle,
    Notification,
    Toolbar,
    Toolbox,
    Panels,

    MapMarker
  },

  props: {
    showPropertyBoundaries: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      hasBoundary: false,
      drawingOptions: {
        borderColor: '#ff00ff',
        borderWidth: 4
      },
      map: null
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      isAdmin: 'huntarea/isAdmin',

      boundary: 'map/boundary/boundary',
      subAreas: 'map/subAreas/getSubAreas',
      forbiddenAreas: 'map/subAreas/getForbiddenAreas',
      markers: 'map/markers/markers',
      trails: 'map/trails/trails',
      lines: 'map/lines/lines',

      importBoundaries: 'map/import/getBoundaries',
      importSubAreas: 'map/import/getSubAreas',
      importForbiddenAreas: 'map/import/getForbiddenAreas',
      importMarkers: 'map/import/getMarkers',

      isPrinting: 'map/print/isPrinting',
      isDeletingOutsideBorder: 'map/markers/isDeletingOutsideBorder'
    }),

    isImporting () {
      return this.$store.getters['map/import/hasData']
    },

    items () {
      return [this.boundary, this.markers, this.subAreas, this.forbiddenAreas] // this.trails, this.lines, this.arrows]
    },

    hasMarkers () {
      return this.markers && this.markers.length > 0
    },

    isLoading () {
      let progress = 0

      this.items.forEach(item => {
        if (item !== null) {
          progress += 1 / this.items.length
        }
      })

      if (progress === 1) {
        return false
      }

      return true
    },

    drawingMode () {
      const mode = this.$store.getters['map/getDrawingMode']
      const color = mode === 'subArea' || mode === 'forbiddenArea'
        ? colors[mode]
        : '#000000'

      this.drawingOptions['borderColor'] = color // eslint-disable-line

      if (mode !== null) {
        return mode
      }

      return null
    },

    mapType () {
      return this.addClass(this.$store.getters['map/settings/mapType'])
    },

    safari () {
      if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
        return 'browser-is-safari'
      }

      return ''
    }
  },

  watch: {
    mapType (type) {
      this.addClass(type)
    }
  },

  created () {
    eventManager.$on('marker:removeOutsideBoundary', this.removeOutsideBoundary)
  },

  destroyed () {
    this.$store.dispatch('map/isPrintActive', false)
    this.$store.commit('map/print/isAdjusting', false)
    this.$store.commit('map/import/cancel')
  },

  methods: {
    async addMarker (latLng) {
      try {
        const defaultMarkerType = 'pass'

        const marker = await this.$store.dispatch('map/markers/create', {
          huntAreaId: this.huntAreaId,
          marker: {
            name: this.getUniqueMarkerName(defaultMarkerType),
            type: defaultMarkerType,
            location: latLng
          }
        })

        this.$store.dispatch('map/markers/select', marker)

        this.$router.push({
          name: 'marker',
          params: {
            markerId: marker.id
          }
        })
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      }
    },

    async addPolygon (coordinates) {
      const drawingMode = this.$store.getters['map/getDrawingMode']
      const name = drawingMode === 'subArea'
        ? `${this.$t('map.general.subArea')} ${this.subAreas.length + 1}`
        : `${this.$t('map.general.forbiddenArea')} ${this.forbiddenAreas.length + 1}`

      try {
        const subArea = await this.$store.dispatch('map/subAreas/create', {
          huntAreaId: this.huntAreaId,
          subArea: {
            name: name,
            type: drawingMode === 'subArea' ? 'saat' : 'forbiddenArea',
            borderColor: colors[drawingMode],
            borderCoordinates: coordinates,
            ...(drawingMode !== 'subArea' ? {
              fillColor: colors[drawingMode],
              fillOpacity: 0.5
            } : {})
          }
        })

        this.$store.dispatch('map/subAreas/select', subArea)

        this.$router.push({
          name: 'subarea',
          params: {
            subAreaId: subArea.id
          }
        })
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      }
    },

    async addLine (data) {
      const { coords, type } = data
      const length = this.lines.filter(line => line.lineType === type).length
      const label = type === 'line'
        ? `${this.$t('map.general.line')} ${length + 1}`
        : `${this.$t('map.general.arrow')} ${length + 1}`

      try {
        const line = await this.$store.dispatch('map/lines/create', {
          huntAreaId: this.huntAreaId,
          line: {
            label: label,
            lineType: type,
            path: coords
          }
        })

        this.$store.dispatch('map/lines/select', line)

        this.$router.push({
          name: 'line',
          params: {
            lineId: line.id
          }
        })
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      }
    },

    hasLoaded (progress) {
      if (progress === 1) {
        this.isLoading = false
      }
    },

    addClass (mapType) {
      const html = document.documentElement

      html.classList.remove('map-has-roadmap-layer', 'map-has-satellite-layer', 'map-has-hybrid-layer')
      html.classList.add(`map-has-${mapType}-layer`)
    },

    color (type) {
      return colors[type] || colors.default
    },

    onMapInitialized (map) {
      this.map = map
    },

    getUniqueMarkerName (markerType) {
      const markerCount = this.markers.filter(marker => marker.type === markerType).length + 1
      return this.$t('map.markers.' + markerType) + ' ' + markerCount
    }
  }
}
</script>

<style lang="scss">
.map-wrap {
  height: 100%;
  flex: 1 1 auto;
  padding: 0;
  position: relative;
  overflow: hidden;

  .map {
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;
  }

  &.browser-is-safari {
    .map {
      height: 100vh;
      width: 100%;
    }
  }

  .map-is-fullscreen & {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
