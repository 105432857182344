<template>
  <MapLabel
    v-if="showLabel"
    :label="name"
    :location="location"
    :visible="labelVisibility"
    :size="labelSize"
    :offset="labelOffset">
  </MapLabel>
</template>

<script>
/* global google */
import { mapGetters } from 'vuex'
import MapLabel from './MapLabel.vue'

export default {
  components: {
    MapLabel
  },

  props: {
    visible: {
      type: Boolean,
      default: true
    },

    location: {
      type: Object,
      required: true
    },

    name: {
      type: String,
      default: ''
    },

    clickable: {
      type: Boolean,
      default: false
    },

    draggable: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    // Extra marker icon that will be drawn behind the normal marker
    backgroundIcon: {
      type: Object, // string | google.maps.Icon | google.maps.Symbol
      default: null
    },

    animation: {
      type: String,
      default: null
    },

    opacity: {
      type: Number,
      default: 1
    },

    anchor: {
      type: Object,
      default: null
    },

    // Allow caller to override shared label visibility setting
    showLabel: {
      type: Boolean,
      default: true
    },

    // Allow caller to override shared icon size setting
    iconSize: {
      type: Number,
      default: null
    }
  },

  inject: [
    'getMap'
  ],

  data () {
    return {
      $map: null,
      $marker: null,
      $backgrounMarker: null
    }
  },

  computed: {
    ...mapGetters({
      markerSize: 'map/markers/size',
      labelSize: 'map/settings/labelSize',
      showLabels: 'map/markers/showLabels'
    }),

    labelVisibility () {
      return this.visible && this.showLabels
    },

    labelOffset () {
      let offset = -12 - (this.markerSize * 32)

      if (this.anchor !== null) {
        offset += this.anchor.y
      }

      return offset
    }
  },

  watch: {
    visible () {
      if (this.$marker) {
        this.$marker.setVisible(this.visible)
      }

      this.updateBackgroundMarkerIcon()
    },

    location () {
      if (this.$marker) {
        this.$marker.setPosition(this.location)
      }
    },

    draggable () {
      if (this.$marker) {
        this.$marker.setDraggable(this.draggable)
      }
    },

    clickable () {
      if (this.$marker) {
        this.$marker.setClickable(this.clickable)
      }
    },

    markerSize () {
      if (this.$marker) {
        this.updateIcon()
      }
    },

    name () {
      if (this.$marker) {
        this.$marker.setOptions({
          title: this.name
        })
      }
    },

    icon () {
      if (this.$marker) {
        this.updateIcon()
      }
    },

    label () {
      this.updateLabel()
    },

    backgroundIcon () {
      this.updateBackgroundMarkerIcon()
    },

    animation () {
      if (this.$marker) {
        this.updateAnimation()
      }
    },

    opacity () {
      if (this.$marker) {
        this.$marker.setOpacity(this.opacity)
      }
    },

    anchor () {
      if (this.anchor !== null) {
        this.updateIcon()
      }
    }
  },

  async mounted () {
    this.$map = await this.getMap()

    const position = new google.maps.LatLng(this.location.lat, this.location.lng)

    const backgrounMarker = new google.maps.Marker({
      map: this.$map,
      visible: false
    })
    this.$backgrounMarker = backgrounMarker

    const marker = new google.maps.Marker({
      position,
      map: this.$map,
      clickable: this.clickable,
      draggable: this.draggable,
      visible: this.visible,
      icon: this.getIcon(),
      title: this.name,
      opacity: this.opacity
    })

    if (marker) {
      this.$marker = marker

      this.updateLabel()
      this.updateAnimation()

      marker.addListener('click', () => {
        if (this.clickable) {
          this.$emit('click')
        }
      })

      marker.addListener('drag', () => {
        const position = {
          lat: Number(this.$marker.getPosition().lat().toFixed(6)),
          lng: Number(this.$marker.getPosition().lng().toFixed(6))
        }

        this.$emit('drag', position)
      })

      backgrounMarker.bindTo('position', this.$marker)
    } else {
      console.error('Failed to create marker.')
    }

    this.updateBackgroundMarkerIcon()
  },

  destroyed () {
    if (this.$marker) {
      this.$marker.setMap(null)
    }

    if (this.$backgrounMarker) {
      this.$backgrounMarker.setMap(null)
    }
  },

  methods: {
    updateAnimation () {
      if (this.animation !== null) {
        const animations = {
          'bounce': google.maps.Animation.BOUNCE,
          'drop': google.maps.Animation.DROP
        }

        this.$marker.setAnimation(animations[this.animation])

        if (this.$backgrounMarker) {
          this.$backgrounMarker.setAnimation(animations[this.animation])
        }
      } else {
        this.$marker.setAnimation(null)

        if (this.$backgrounMarker) {
          this.$backgrounMarker.setAnimation(null)
        }
      }
    },

    updateIcon () {
      this.$marker.setOptions({
        icon: this.getIcon()
      })
    },

    updateLabel () {
      if (!this.$marker) {
        return
      }

      var label = null
      if (this.label) {
        label = {
          text: this.label,
          color: '#FFFFFF'
        }
      }

      this.$marker.setLabel(label)
    },

    updateBackgroundMarkerIcon () {
      if (!this.$marker || !this.$backgrounMarker) {
        return
      }

      var options = {
        icon: this.backgroundIcon,
        visible: this.backgroundIcon != null && this.$marker.getVisible()
      }

      this.$backgrounMarker.setOptions(options)
    },

    getIcon () {
      const iconSize = this.iconSize !== null ? this.iconSize : this.markerSize * 32
      const anchor = this.anchor !== null ? this.anchor : { x: iconSize / 2, y: iconSize }

      return {
        url: this.icon,
        scaledSize: new google.maps.Size(iconSize, iconSize),
        anchor: new google.maps.Point(anchor.x, anchor.y),
        labelOrigin: new google.maps.Point(anchor.x, anchor.y * 0.5 - 3)
      }
    }
  }
}
</script>
