<template>
  <Panel :isActive="isActive">
    <PanelTitle :back="false" @close="close">
      <span slot="title">{{ $t('map.print.title') }}</span>
    </PanelTitle>

    <PanelContent v-if="isIE">
      <Alert type="danger">
        {{ $t('general.unsupportedBrowserMessage') }}
      </Alert>
    </PanelContent>

    <PanelContent v-else>
      <FormField :label="$t('map.print.size')">
        <div class="buttons has-addons">
          <button
            v-for="(val, key) in sizes"
            :key="key"
            :class="{ 'is-secondary': paperSize === key }"
            class="button"
            @click="paperSize = key">
            {{ val }}
          </button>
        </div>
      </FormField>

      <FormField :label="$t('map.print.orientation')">
        <div class="buttons has-addons">
          <button
            v-for="(val, key) in orientations"
            :key="key"
            :class="{ 'is-secondary': orientation === key }"
            class="button"
            @click="orientation = key">
            {{ $t('map.print.' + key) }}
          </button>
        </div>
      </FormField>

      <button
        class="button is-primary print-button push-top-xl"
        :disabled="map === null"
        @click="startPrint">
        <Icon name="icon-pdf" />
        <span>{{ $t('map.print.createButtonTitle') }}</span>
      </button>
    </PanelContent>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventManager } from '@/main'
import Print from '@/components/map/print/print.js'

export default {
  props: {
    map: {
      type: Object,
      default: () => null
    }
  },

  data () {
    return {
      orientations: {
        portrait: this.$t('map.print.portrait'),
        landscape: this.$t('map.print.landscape')
      },
      sizes: {
        a4: 'A4',
        a3: 'A3',
        a2: 'A2',
        a1: 'A1',
        a0: 'A0'
      }
    }
  },

  computed: {
    ...mapGetters({
      area: 'huntarea/selected',
      boundary: 'map/boundary/boundary'
    }),

    isActive: {
      get () { return this.$store.getters['map/isPrintActive'] },
      set (state) { return this.$store.dispatch('map/isPrintActive', state) }
    },

    isPrinting: {
      get () { return this.$store.getters['map/print/isPrinting'] },
      set (bool) { return this.$store.commit('map/print/isPrinting', bool) }
    },

    isAdjusting: {
      get () { return this.$store.getters['map/print/isAdjusting'] },
      set (bool) { return this.$store.commit('map/print/isAdjusting', bool) }
    },

    paperSize: {
      get () { return this.$store.getters['map/print/paperSize'] },
      set (size) { return this.$store.commit('map/print/setPaperSize', size) }
    },

    orientation: {
      get () { return this.$store.getters['map/print/paperOrientation'] },
      set (orientation) { return this.$store.commit('map/print/setPaperOrientation', orientation) }
    },

    areaName () {
      return this.area && this.area.name.toLowerCase()
    },

    isIE () {
      return this.$utils.isInternetExplorer()
    }
  },

  watch: {
    isActive (state) {
      if (this.isIE) return // Suppress the overlay in IE

      this.isAdjusting = state
    }
  },

  beforeMount () {
    if (document.documentElement.clientHeight < 900) {
      this.orientation = 'landscape'
    }
  },

  created () {
    eventManager.$on('map:printEnd', () => {
      // this.isAdjusting = false
      this.isPrinting = false
    })
  },

  render () {
    return ''
  },

  beforeRouteLeave (to, from, next) {
    this.isAdjusting = false
    next()
  },

  methods: {
    async startPrint () {
      this.isPrinting = true
      // this.isAdjusting = false

      return new Print(this.map, {
        name: this.areaName,
        paperSize: this.paperSize,
        orientation: this.orientation,
        propertyLayerVisible: this.$store.getters['map/settings/isPropertyLayerVisible']
      })
    },

    close () {
      this.cancelAdjust()
      this.isActive = false
    },

    startAdjust () {
      this.isAdjusting = true
    },

    cancelAdjust () {
      this.isAdjusting = false
    }
  }
}
</script>
