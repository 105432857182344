<template>
  <div
    :class="[filterClass]"
    class="layer-trail"
    @click="setSelected(!isSelected)">
    <i
      :class="icon"
      class="icon"
      @click.stop.prevent="toggleVisibility">
    </i>

    <span class="title">{{ trail.name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    trail: {
      type: Object,
      default: () => {
        return {
          name: '',
          visible: true
        }
      }
    }
  },

  computed: {
    isSelected () {
      return this.$store.getters['map/trails/selected'] === this.trail
    },

    isVisible () {
      return this.trail.visible
    },

    filterClass () {
      return {
        'is-dimmed': !this.isVisible,
        'is-selected': this.isSelected
      }
    },

    icon () {
      return this.isVisible ? 'icon-eye' : 'icon-eye-off'
    }
  },

  methods: {
    setSelected (selected) {
      this.$store.dispatch('map/select', {
        type: 'trails',
        data: selected ? this.trail : null
      })

      this.hidePanels()
    },

    hidePanels () {
      this.$router.push({
        name: 'map'
      })
    },

    toggleVisibility () {
      this.trail.visible = !this.trail.visible

      if (this.$parent.updateVisibility !== undefined) {
        this.$parent.updateVisibility()
      }
    },

    setVisible (visibility) {
      this.trail.visible = visibility
    }
  }
}
</script>

<style lang="scss">
.layer-trail {
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;

  &:hover {
    background: aliceblue;
  }

  &.is-selected {
    background: lemonchiffon;
  }

  .title {
    font-size: 0.85rem;
    font-weight: normal;
    padding-left: 0.5rem;
  }
}
</style>
