export default {
  a4: {
    portrait: {
      width: 210,
      height: 297
    },
    landscape: {
      width: 297,
      height: 210
    },
    markerSize: 1.25,
    labelSize: 2,
    strokeWeight: 4
  },

  a3: {
    portrait: {
      width: 297,
      height: 420
    },
    landscape: {
      width: 420,
      height: 297
    },
    markerSize: 1.75,
    labelSize: 2.5,
    strokeWeight: 6
  },

  a2: {
    portrait: {
      width: 420,
      height: 594
    },
    landscape: {
      width: 594,
      height: 420
    },
    markerSize: 2.5,
    labelSize: 3,
    strokeWeight: 8
  },

  a1: {
    portrait: {
      width: 594,
      height: 841
    },
    landscape: {
      width: 841,
      height: 594
    },
    markerSize: 3.5,
    labelSize: 4,
    strokeWeight: 12
  },

  a0: {
    portrait: {
      width: 841,
      height: 1189
    },
    landscape: {
      width: 1189,
      height: 841
    },
    markerSize: 5.5,
    labelSize: 5,
    strokeWeight: 16
  }
}
