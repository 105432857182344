<template>
  <ImportPanelGroup
    v-if="boundaries.length > 0"
    :backgroundColor="backgroundColor"
    :title="title"
    text-color="light"
    @toggle-selection="toggleSelection">
    <ImportPanelGroupItem v-for="(boundary, i) in boundaries" :key="i" :item="boundary">
      <div class="control" :class="{ 'is-dimmed': !boundary.selected }">
        <label>{{ $t('map.import.boundary') }}</label>
      </div>
    </ImportPanelGroupItem>
  </ImportPanelGroup>
</template>

<script>
import { mapGetters } from 'vuex'
import colors from '@/components/map/utils/colors'
import ImportPanelGroup from './ImportPanelGroup.vue'
import ImportPanelGroupItem from './ImportPanelGroupItem.vue'

export default {
  components: {
    ImportPanelGroup,
    ImportPanelGroupItem
  },

  computed: {
    ...mapGetters({
      boundaries: 'map/import/getBoundaries'
    }),

    title () {
      return this.$i18n.t('map.import.boundaries')
    },

    backgroundColor () {
      return colors['boundary']
    }
  },

  methods: {
    toggleSelection (selected) {
      this.boundaries.forEach(boundary => {
        boundary.selected = selected
      })
    }
  }
}
</script>

<style scoped>
.control {
  width: 100%;
}
</style>
