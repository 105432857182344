<template>
  <Accordion :background="backgroundColor" :text="textColor">
    <div slot="title" class="flex">
      <Checkbox v-model="selectAllItems" class="checkbox-wrap" />
      {{ title }}
    </div>

    <slot />
  </Accordion>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    backgroundColor: {
      type: String,
      default: '#dce4e2'
    },

    textColor: {
      type: String,
      default: 'light'
    }
  },

  data () {
    return {
      selectAllItems: true
    }
  },

  watch: {
    selectAllItems () {
      this.$emit('toggle-selection', this.selectAllItems)
    }
  }
}
</script>

<style scoped>
.checkbox-wrap {
  padding-left: 0.25rem;
}
</style>
