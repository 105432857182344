<template>
  <Panel :isActive="isActive" class="import-panel">
    <PanelTitle :back="false" @close="close">
      <span slot="title">{{ $t('map.import.title') }}</span>
    </PanelTitle>

    <PanelContent scrollable>
      <div v-if="hasImportData">
        <ImportPanelFileMetaData />

        <Divider />

        <ImportPanelBoundaryGroup />
        <ImportPanelSubAreasGroup />
        <ImportPanelForbiddenAreasGroup />
        <ImportPanelMarkerGroup />

        <Alert v-if="hasNoSupportedData" type="info">
          {{ $t('map.import.noSupportedDataMessage') }}
        </Alert>

        <Divider />

        <ButtonGroup>
          <Button :title="$t('general.cancel')" @click="cancel" />
          <Button
            type="primary"
            :disabled="totalSelected === 0"
            :title="$t('map.import.title')"
            @click="confirmImport">
          </Button>
        </ButtonGroup>
      </div>

      <ImportPanelFilePicker v-else />
    </PanelContent>

    <ImportConfirmDialog
      v-if="showConfirmDialog"
      @close="closeConfirmDialog"
      @confirm="save" />

    <ImportProgressModal />
  </Panel>
</template>

<script>
import ImportProgressModal from './ImportProgressModal.vue'
import ImportPanelFilePicker from './ImportPanelFilePicker.vue'
import ImportPanelFileMetaData from './ImportPanelFileMetaData.vue'
import ImportPanelBoundaryGroup from './ImportPanelBoundaryGroup.vue'
import ImportPanelSubAreasGroup from './ImportPanelSubAreasGroup.vue'
import ImportPanelForbiddenAreasGroup from './ImportPanelForbiddenAreasGroup.vue'
import ImportPanelMarkerGroup from './ImportPanelMarkerGroup.vue'
import ImportConfirmDialog from './ImportConfirmDialog.vue'

export default {
  components: {
    ImportPanelFilePicker,
    ImportPanelFileMetaData,
    ImportPanelBoundaryGroup,
    ImportPanelSubAreasGroup,
    ImportPanelForbiddenAreasGroup,
    ImportPanelMarkerGroup,
    ImportProgressModal,
    ImportConfirmDialog
  },

  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      showConfirmDialog: false
    }
  },

  computed: {
    isActive: {
      get () { return this.$store.getters['map/isImportActive'] },
      set (state) { return this.$store.dispatch('map/isImportActive', state) }
    },

    hasImportData () {
      return this.$store.getters['map/import/hasData']
    },

    hasNoSupportedData () {
      const boundaries = this.$store.getters['map/import/getBoundaries']
      const subAreas = this.$store.getters['map/import/getSubAreas']
      const forbiddenAreas = this.$store.getters['map/import/getForbiddenAreas']
      const markers = this.$store.getters['map/import/getMarkers']

      return boundaries.length === 0 && subAreas.length === 0 && forbiddenAreas.length === 0 && markers.length === 0
    },

    totalSelected () {
      return this.$store.getters['map/import/getAllObjects'].filter(object => object.selected).length
    }
  },

  methods: {
    cancel () {
      this.$store.commit('map/import/cancel')
    },

    close () {
      this.cancel()
      this.isActive = false
    },

    confirmImport () {
      this.showConfirmDialog = true
    },

    closeConfirmDialog () {
      this.showConfirmDialog = false
    },

    async save () {
      this.closeConfirmDialog()

      await this.$store.dispatch('map/import/save')

      this.showNotification()
      this.close()
    },

    showNotification () {
      const failedImports = this.$store.getters['map/import/getFailedObjects']
      if (failedImports.length === 0) {
        this.$notification.success(this.$i18n.t('map.import.importSucceededMessage'))
      } else {
        this.$notification.danger(this.$i18n.t('map.import.importFailedErrorMessage'))
      }
    }
  }
}
</script>

<style lang="scss">
.import-panel label {
  font-size: 0.85rem;
}
</style>
