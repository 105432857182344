<template>
  <div
    :class="[filterClass]"
    class="layer-subarea"
    @click="toggleSelected">
    <i
      :class="icon"
      class="icon"
      @click.stop.prevent="toggleVisibility">
    </i>

    <span class="title">{{ this.$t('map.general.huntingGround') }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary'
    }),

    selected: {
      get () { return this.$store.getters['map/boundary/selected'] },
      set (state) { this.$store.dispatch('map/boundary/select', state) }
    },

    isVisible () {
      return this.boundary.visible
    },

    filterClass () {
      return {
        'is-dimmed': !this.isVisible,
        'is-selected': this.boundary.selected
      }
    },

    icon () {
      return this.isVisible ? 'icon-eye' : 'icon-eye-off'
    }
  },

  methods: {
    toggleSelected () {
      this.selected = !this.selected

      this.showPanel()
    },

    showPanel () {
      this.$router.push({
        name: 'boundary'
      })
    },

    toggleVisibility () {
      this.boundary.visible = !this.boundary.visible

      if (this.$parent.updateVisibility !== undefined) {
        this.$parent.updateVisibility()
      }
    },

    setVisible (visibility) {
      this.boundary.visible = visibility
    }
  }
}
</script>
