<template>
  <a
    :class="{ 'is-primary': isExportActive }"
    :title="$t('map.tools.export')"
    class="button is-white"
    @click="toggleExport">
    <Icon name="icon-download" />
  </a>
</template>

<script>
export default {
  computed: {
    isExportActive: {
      get () { return this.$store.getters['map/isExportActive'] },
      set (state) { return this.$store.dispatch('map/isExportActive', state) }
    }
  },

  methods: {
    toggleExport () {
      this.isExportActive = !this.isExportActive
    }
  }
}
</script>
